<template>
    <v-container fluid style="padding-bottom:80px">
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
                <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                    <v-layout align-center pr-4>
                    <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                    <v-layout column>
                        <div>
                        <strong>{{ snackbar.title }}</strong>
                        </div>
                        <div>{{ snackbar.text }}</div>
                    </v-layout>
                    </v-layout>
                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                    <v-icon>clear</v-icon>
                    </v-btn>
                </v-snackbar>
            </v-col>
        </v-row>
        <v-row class="mt-4">
            <v-col cols="12">
                <v-tabs
                    v-model="tab"
                    color="#1565c0"
                    class="round_top"
                    icons-and-text
                    style="width: fit-content;margin-left: 25px;"
                >
                    <v-tabs-slider color="primary"></v-tabs-slider>

                    <v-tab href="#tab-1" active-class="active-tab">
                        Order Master
                    </v-tab>

                    <v-tab href="#tab-2" active-class="active-tab" :disabled="disabled_order_item">
                        Order Item
                    </v-tab>

                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item
                        value="tab-1"
                    >
                        <v-card class="rounded-xl m-1 elevation-2">
                            <v-card-text>
                                <v-container fluid>
                                    <v-row class="mb-1" v-show="alertban90">
                                        <v-col cols="12">
                                            <div>
                                                <v-alert
                                                outlined
                                                type="error"
                                                prominent
                                                border="left"
                                                class="mb-1"
                                                >
                                                    <h5 class="text-h5 text-weight">
                                                        Ada piutang >=90 hari sejak tgl surat jalan
                                                    </h5>
                                                    <!-- <div> -->
                                                    <span>
                                                        Maaf Tidak bisa lanjut ke approve 1, jika belum di approve 0 oleh finance
                                                    </span>
                                                    <!-- </div> -->
                                                </v-alert>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row class="mt-3">
                                        <v-col cols="12" xs="12" sm="6" md="2" class="col-12 pt-0 pb-1">
                                            <h6 class="blue-lcd mb-1">Office <span class="red--text"><strong>* </strong></span></h6>   
                                            <v-autocomplete
                                                dense
                                                solo
                                                clearable
                                                v-model="office"
                                                :items="offices"
                                                item-value="office_id"
                                                item-text="office"
                                                class="ma-0 pa-0 border-12"
                                                @change="(event) => getSales(event)"
                                                hide-details=true
                                                :disabled="disabled_office"
                                            ></v-autocomplete>  
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="2" class="col-12 pt-0 pb-1">
                                            <h6 class="blue-lcd mb-1">Division <span class="red--text"><strong>* </strong></span></h6>   
                                            <v-autocomplete
                                                dense
                                                solo
                                                clearable
                                                v-model="division"
                                                :items="divisions"
                                                item-value="str1"
                                                item-text="str2"
                                                class="ma-0 pa-0 border-12"
                                                hide-details=true
                                                :disabled="disabled_division == true"
                                            ></v-autocomplete>  
                                        </v-col>                                                
                                        <v-col class="col-12 pt-0 pb-1" sm="6" md="2" >
                                            <h6 class="blue-lcd mb-1">Order Date <span class="red--text"><strong>* </strong></span></h6>
                                            <v-menu
                                                ref="modal"
                                                v-model="modal"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="auto"
                                                disabled
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                    dense
                                                    solo
                                                    v-model="order_date"
                                                    persistent-hint
                                                    append-icon="mdi-calendar"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    class="ma-0 pa-0 border-12"
                                                    hide-details=true
                                                    readonly
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    v-model="order_date"
                                                    no-title
                                                    readonly
                                                    @input="modal = false"
                                                ></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col class="col-12 pt-0 pb-1" sm="6" md="3">
                                            <h6 class="blue-lcd mb-1">Sales ID <span class="red--text"><strong>* </strong></span></h6>
                                            <v-autocomplete
                                                solo
                                                clearable
                                                dense
                                                v-model="sales"
                                                :items="saleses"
                                                item-value="SalesId"
                                                item-text="NamaSales"
                                                class="ma-0 pa-0 border-12"
                                                @change="(event) => changeApplConstanDivision(event)"
                                                hide-details=true
                                                :return-object="true"
                                                :disabled="disabled_sales == true"
                                            ></v-autocomplete>
                                        </v-col>
                                        
                                        <v-col class="col-12 pt-0 pb-1" sm="6" md="2">
                                            <h6 class="blue-lcd mb-1">Status</h6>
                                            <v-text-field
                                                dense
                                                solo
                                                persistent-hi
                                                v-model="status"
                                                class="ma-0 pa-0 border-12"
                                                hide-details=true
                                                readonly
                                            >
                                            </v-text-field> 
                                        </v-col>
                                        <v-col class="col-12 pt-1 pb-1" sm="6" md="2">
                                            <h6 class="blue-lcd mb-1">Order Type <span class="red--text"><strong>* </strong></span></h6>
                                            <v-autocomplete
                                                dense
                                                solo
                                                clearable
                                                v-model="order_type"
                                                :items="order_types"
                                                item-value="value"
                                                item-text="name"
                                                class="ma-0 pa-0 border-12"
                                                hide-details=true
                                                @change="(event) => changeOrderType(event)"
                                                :readonly="readonly_order_type"
                                            ></v-autocomplete> 
                                        </v-col>
                                        
                                        <!-- <v-col class="col-12 pt-0 pb-1" sm="6" md="3">
                                            <h6 class="blue-lcd mb-1">Order ID <span class="red--text"><strong>* </strong></span></h6>
                                            <v-autocomplete
                                                solo
                                                dense
                                                clearable
                                                v-model="order"
                                                :items="orders"
                                                item-value="name"
                                                item-text="order_id"
                                                class="ma-0 pa-0 border-12"
                                                hide-details=true
                                            ></v-autocomplete>
                                        </v-col> -->
                                        <v-col class="col-12 pt-1 pb-1" sm="6" md="1">
                                            <h6 class="blue-lcd mb-1">REV</h6>
                                            <v-text-field
                                                solo
                                                dense
                                                persistent-hi
                                                v-model="rev"
                                                class="ma-0 pa-0 border-12"
                                                hide-details=true
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <!-- <v-col class="col-12 pt-1 pb-0" sm="6" md="2">
                                            <div class="d-flex mt-2">
                                                <v-btn class="rounded-l text-h6 mt-3 mr-2" color="primary">Gen</v-btn>
                                                <v-btn class="rounded-l text-white text-h6 mt-3" color="green">New</v-btn>
                                            </div>
                                        </v-col> -->
                                        <v-col class="col-12 pt-1 pb-1" sm="6" md="3">
                                            <h6 class="blue-lcd mb-1">Project ID</h6>
                                            <v-autocomplete
                                                dense
                                                solo
                                                clearable
                                                v-model="project"
                                                :items="projects"
                                                item-value="pr_id"
                                                :item-text="item => item.pr_name +' - '+ item.location"
                                                class="ma-0 pa-0 border-12"
                                                hide-details=true
                                            ></v-autocomplete>  
                                        </v-col>
                                        <v-col class="col-12 pt-1 pb-1" sm="6" md="4">
                                            <h6 class="blue-lcd mb-1">Customer ID <span class="red--text"><strong>* </strong></span></h6>
                                            <v-autocomplete
                                                solo
                                                dense
                                                clearable
                                                v-model="customer"
                                                :items="customers"
                                                :search-input.sync="searchCustomer"
                                                cache-items
                                                hide-no-data
                                                hide-details
                                                allow-overflow
                                                item-value="CustomerId"
                                                item-text="NamaCustomer"
                                                class="ma-0 pa-0 border-12"
                                                :return-object="true"
                                                @change="(event) => getConsignee(event)"
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="2" class="col-12 pt-1 pb-1">
                                            <h6 class="blue-lcd mb-1">ID Card <span class="red--text"><strong>* </strong></span></h6>   
                                            <v-radio-group
                                                v-model="id_card"
                                                row
                                                dense
                                                hide-details=true
                                                class="pt-2 pb-0 mt-0"
                                                @change="(event) => getNikNpwpCustomer(event)"

                                            >
                                                <v-radio
                                                    label="NIK"
                                                    value="K"
                                                    class="pt-0 pb-0 mt-0"
                                                ></v-radio>
                                                <v-radio
                                                    label="NPWP"
                                                    value="P"
                                                    class="pt-0 pb-0 mt-0"
                                                ></v-radio>
                                            </v-radio-group>
                                        </v-col>
                                        <v-col class="col-12 " sm="6" md="2">
                                            <h6 class="blue-lcd mb-1">NPWP / NIK</h6>
                                            <v-autocomplete
                                                solo
                                                dense
                                                clearable
                                                v-model="id_carded"
                                                :items="id_cards"
                                                item-value="nik_npwp"
                                                item-text="nik_npwp"
                                                class="ma-0 pa-0 border-12"
                                                hide-details=true
                                                @change="(event) => getNoNikNpwp(event)"
                                                :return-object="true"
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col class="col-12 " sm="6" md="2">
                                            <h6 class="blue-lcd mb-1">*</h6>
                                            <v-text-field
                                                solo
                                                dense
                                                persistent-hi
                                                v-model="id_card_nik"
                                                class="ma-0 pa-0 border-12"
                                                hide-details=true
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col class="col-12 pt-2" sm="6" md="1">
                                            <v-btn class="rounded-l text-h6 text-white mr-2 mt-5" color="green" v-if="customer == '' || id_card == ''" disabled><v-icon>mdi-plus</v-icon> Add</v-btn>
                                            <v-btn class="rounded-l text-h6 text-white mr-2 mt-5" color="green" v-else @click="showDialogNik()"><v-icon>mdi-plus</v-icon> Add</v-btn>
                                        </v-col>
                                        <v-col class="col-12" sm="6" md="4">
                                            <h6 class="blue-lcd mb-1">No SKDBN</h6>
                                            <v-text-field
                                                solo
                                                dense
                                                label="No SKDBN"
                                                persistent-hi
                                                v-model="no_skdbn"
                                                class="ma-0 pa-0 border-12"
                                                hide-details=true
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col class="col-12" sm="6" md="3">
                                            <h6 class="blue-lcd mb-1">Cust PO No</h6>
                                            <v-text-field
                                                solo
                                                dense
                                                label="Cust PO No"
                                                persistent-hi
                                                v-model="cust_po_no"
                                                class="ma-0 pa-0 border-12"
                                                hide-details=true
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col class="col-12" sm="12" md="12">
                                            <v-switch
                                                inset
                                                v-model="switchCredit"
                                                label="More Option"
                                            ></v-switch>
                                        </v-col>
                                    </v-row>
                                    <v-row class="mt-3" v-if="switchCredit">
                                        <v-col cols="12">
                                            <div class="table-responsive">
                                                <table class="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <td scope="col" rowspan="2" style="vertical-align: middle;text-align: center;">Not Yed Due</td>
                                                            <td scope="col" colspan="4" style="vertical-align: middle;text-align: center;">Over Due</td>
                                                            <td scope="col" rowspan="2" style="vertical-align: middle;text-align: center;">Total</td>
                                                            <td scope="col" rowspan="2" style="vertical-align: middle;text-align: center;">Credit Limit</td>
                                                        </tr>
                                                        <tr>
                                                            <td scope="col" style="vertical-align: middle;text-align: center;">1 - 30</td>
                                                            <td scope="col" style="vertical-align: middle;text-align: center;">31 - 60</td>
                                                            <td scope="col" style="vertical-align: middle;text-align: center;">61 - 90</td>
                                                            <td scope="col" style="vertical-align: middle;text-align: center;">> 90</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody v-if="credits.length > 0">
                                                        <tr v-for="(credit, index) in credits" :key="index">
                                                            <td scope="col" style="vertical-align: middle;text-align: center;">IDR. {{$store.getters.convertToCurrencyUs(credit.belumJatuhTempo)}}</td>
                                                            <td scope="col" style="vertical-align: middle;text-align: center;">IDR. {{$store.getters.convertToCurrencyUs(credit.jatuhTempLebihSatuSampai30Hari)}}</td>
                                                            <td scope="col" style="vertical-align: middle;text-align: center;">IDR. {{$store.getters.convertToCurrencyUs(credit.jatuhTempLebih31Sampai60Hari)}}</td>
                                                            <td scope="col" style="vertical-align: middle;text-align: center;">IDR. {{$store.getters.convertToCurrencyUs(credit.jatuhTempLebih61Sampai90Hari)}}</td>
                                                            <td scope="col" style="vertical-align: middle;text-align: center;">IDR. {{$store.getters.convertToCurrencyUs(credit.jatuhTempLebih90Hari)}}</td>
                                                            <td scope="col" style="vertical-align: middle;text-align: center;">
                                                                IDR. {{$store.getters.convertToCurrencyUs(calculate(credit))}}
                                                            </td>
                                                            <td scope="col" style="vertical-align: middle;text-align: center;">IDR. {{$store.getters.convertToCurrencyUs(credit.credit_limit)}}</td>
                                                        </tr>
                                                    </tbody>
                                                    <tbody v-else>
                                                        <tr>
                                                            <td scope="col" style="vertical-align: middle;text-align: center;">0</td>
                                                            <td scope="col" style="vertical-align: middle;text-align: center;">0</td>
                                                            <td scope="col" style="vertical-align: middle;text-align: center;">0</td>
                                                            <td scope="col" style="vertical-align: middle;text-align: center;">0</td>
                                                            <td scope="col" style="vertical-align: middle;text-align: center;">
                                                                0
                                                            </td>
                                                            <td scope="col" style="vertical-align: middle;text-align: center;">0</td>
                                                            <td scope="col" style="vertical-align: middle;text-align: center;">0</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row class="mt-3" v-if="switchCredit">
                                        <v-col class="col-12" sm="6" md="12">
                                            <div class="d-flex">
                                                <h5 class="blue-lcd mr-4">
                                                Credit Used Include Old Data in KKA
                                                </h5>
                                                <p class="mt-1">
                                                    * Field are required. Please fill your fileds and submit for save
                                                </p>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row class="mt-3" v-if="switchCredit">
                                        <v-col class="col-12" sm="6" md="4">
                                            <h6 class="blue-lcd mb-1">Piutang Outstanding</h6>
                                            <v-text-field
                                                solo
                                                dense
                                                persistent-hi
                                                v-model="piutang_outstanding"
                                                class="ma-0 pa-0 border-12"
                                                hide-details=true
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col class="col-12" sm="6" md="4">
                                            <h6 class="blue-lcd mb-1">New Order</h6>
                                            <v-text-field
                                                solo
                                                dense
                                                persistent-hi
                                                v-model="new_order"
                                                class="ma-0 pa-0 border-12"
                                                hide-details=true
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col class="col-12" sm="6" md="4">
                                            <h6 class="blue-lcd mb-1">Remaining Credit Allowed</h6>
                                            <v-text-field
                                                solo
                                                dense
                                                persistent-hi
                                                v-model="remaining_credit"
                                                class="ma-0 pa-0 border-12"
                                                hide-details=true
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col class="col-12" sm="6" md="6">
                                            <h6 class="blue-lcd mb-1">Outstanding Order</h6>
                                            <v-text-field
                                                solo
                                                dense
                                                persistent-hi
                                                v-model="outstanding_order"
                                                class="ma-0 pa-0 border-12"
                                                hide-details=true
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col class="col-12" sm="6" md="6">
                                            <h6 class="blue-lcd mb-1">Outstanding PreOrder</h6>
                                            <v-text-field
                                                solo
                                                dense
                                                persistent-hi
                                                v-model="outstanding_pre_order"
                                                class="ma-0 pa-0 border-12"
                                                hide-details=true
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row class="mt-3">
                                        <v-col class="col-12" sm="6" md="12">
                                            <div class="d-flex">
                                                <h5 class="blue-lcd mr-4">
                                                Production & Shipment
                                                </h5>
                                                <p class="mt-1">
                                                    * Field are required. Please fill your fileds and submit for save
                                                </p>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="col-12" sm="6" md="2">
                                            <h6 class="blue-lcd mb-1">Mill ID <span class="red--text"><strong>* </strong></span></h6>
                                            <v-autocomplete
                                                solo
                                                dense
                                                clearable
                                                v-model="mill"
                                                :items="mills"
                                                item-value="mill_id"
                                                item-text="mill_name"
                                                class="ma-0 pa-0 border-12"
                                                hide-details=true
                                                @change="(event) => getMachineType(event)"
                                                return-object
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col class="col-12" sm="6" md="2">
                                            <h6 class="blue-lcd mb-1">Req Ship Date <span class="red--text"><strong>* </strong></span></h6>
                                            <v-menu
                                                ref="modal_ship"
                                                v-model="modal_ship"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="auto"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                    solo
                                                    dense
                                                    v-model="ship_date"
                                                    persistent-hint
                                                    append-icon="mdi-calendar"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    class="ma-0 pa-0 border-12"
                                                    hide-details=true
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    v-model="ship_date"
                                                    :min="ship_date_now"
                                                    no-title
                                                    @input="modal_ship = false"
                                                ></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col class="col-12" sm="6" md="2">
                                            <h6 class="blue-lcd mb-1">Ship Term <span class="red--text"><strong>* </strong></span></h6>
                                            <v-autocomplete
                                                solo
                                                dense
                                                clearable
                                                v-model="ship_term"
                                                :items="ship_terms"
                                                item-value="ship_term"
                                                item-text="ship_term"
                                                class="ma-0 pa-0 border-12"
                                                hide-details=true
                                                @change="(event) => setExpedisi(event)"
                                                :readonly="ship_term_readonly"
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col class="col-12" sm="6" md="2">
                                            <h6 class="blue-lcd mb-1">Pay Term <span class="red--text"><strong>* </strong></span></h6>
                                            <v-autocomplete
                                                solo
                                                dense
                                                clearable
                                                v-model="pay_term"
                                                :items="pay_terms"
                                                item-value="pay_term"
                                                item-text="pay_term_desc"
                                                class="ma-0 pa-0 border-12"
                                                hide-details=true
                                                :readonly="pay_term_readonly"
                                            ></v-autocomplete>
                                        </v-col>
                                    </v-row>
                                    <v-row class="mt-2">
                                        <v-col class="col-12" sm="6" md="2">
                                            <h6 class="blue-lcd mb-1">Consigne ID</h6>
                                            <v-autocomplete
                                                solo
                                                dense
                                                clearable
                                                v-model="consign"
                                                :items="consigns"
                                                item-value="cons_id"
                                                item-text="cons_name"
                                                class="ma-0 pa-0 border-12"
                                                hide-details=true
                                                @change="(event) => setConsigneeName(event)"
                                                :return-object="true"
                                                :disabled="disabled_consignee"
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col class="col-12" sm="6" md="8">
                                            <h6 class="blue-lcd mb-1">Consigne Desc</h6>
                                            <v-text-field
                                                solo
                                                dense
                                                persistent-hint
                                                v-model="consign_name"
                                                class="ma-0 pa-0 border-12"
                                                hide-details=true
                                                readonly
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col class="col-12" sm="6" md="10">
                                            <h6 class="blue-lcd mb-1">Ship to</h6>
                                            <v-text-field
                                                solo
                                                dense
                                                persistent-hi
                                                v-model="ship_to"
                                                class="ma-0 pa-0 border-12"
                                                hide-details=true
                                                :readonly="readonly_ship_to"
                                                :disabled="disabled_ship_to"
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col class="col-12 pt-2" sm="6" md="2" v-show="readonly_ship_to">
                                            <v-btn class="rounded-l text-h6 text-white mr-2 mt-5" color="green" @click="dialogDeliveryNotePicker = true"><v-icon>mdi-plus</v-icon> Add Note Picker</v-btn>
                                        </v-col>
                                        <v-col class="col-12" sm="6" md="2">
                                            <h6 class="blue-lcd mb-1">Shipment Province</h6>
                                            <v-autocomplete
                                                solo
                                                dense
                                                clearable
                                                v-model="shipment_province"
                                                :items="shipment_provinces"
                                                item-value="province"
                                                item-text="province"
                                                class="ma-0 pa-0 border-12"
                                                hide-details=true
                                                @change="(event) => getShipmentCity(event)"
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col class="col-12" sm="6" md="2">
                                            <h6 class="blue-lcd mb-1">Shipment City</h6>
                                            <v-autocomplete
                                                solo
                                                dense
                                                clearable
                                                v-model="shipment_city"
                                                :items="shipment_cities"
                                                item-value="city"
                                                item-text="city"
                                                class="ma-0 pa-0 border-12"
                                                hide-details=true
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col class="col-12" sm="6" md="2" v-show="showExpedisi">
                                            <h6 class="blue-lcd mb-1">Expedisi</h6>
                                            <v-text-field
                                                solo
                                                dense
                                                persistent-hi
                                                v-model="expedisi"
                                                class="ma-0 pa-0 border-12"
                                                hide-details=true
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col class="col-12" sm="6" md="2" v-show="show_no_telpon_expedisi">
                                            <h6 class="blue-lcd mb-1">Telp</h6>
                                            <v-text-field
                                                solo
                                                dense
                                                persistent-hi
                                                v-model="no_telp"
                                                class="ma-0 pa-0 border-12"
                                                hide-details=true
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col class="col-12" sm="6" md="6" v-show="showExpedisi">
                                            <h6 class="blue-lcd mb-1">Alamat</h6>
                                            <v-text-field
                                                solo
                                                dense
                                                persistent-hi
                                                v-model="address"
                                                class="ma-0 pa-0 border-12"
                                                hide-details=true
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col class="col-12" sm="6" md="4">
                                            <h6 class="blue-lcd mb-1">Remark</h6>
                                            <v-text-field
                                                solo
                                                dense
                                                persistent-hi
                                                v-model="remark"
                                                class="ma-0 pa-0 border-12"
                                                hide-details=true
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col class="col-12" sm="6" md="2">
                                            <h6 class="blue-lcd mb-1">Total Packing</h6>
                                            <v-text-field
                                                solo
                                                dense
                                                persistent-hi
                                                v-model="total_packing"
                                                class="ma-0 pa-0 border-12"
                                                hide-details=true
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col class="col-12" sm="6" md="2">
                                            <h6 class="blue-lcd mb-1">Down Payment</h6>
                                            <v-text-field
                                                solo
                                                dense
                                                persistent-hi
                                                v-model="down_payment"
                                                class="ma-0 pa-0 border-12"
                                                hide-details=true
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row class="mt-5" v-if="switchCredit">
                                        <v-col class="col-12" sm="6" md="12">
                                            <div class="d-flex">
                                                <h5 class="blue-lcd mr-4">
                                                Other
                                                </h5>
                                                <p class="mt-1">
                                                    * Field are required. Please fill your fileds and submit for save
                                                </p>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="switchCredit">
                                        <v-col cols="12" xs="12" sm="6" md="4" class="pt-0 pb-0">
                                            <h6 class="blue-lcd mb-1">Kontrak</h6>   
                                            <v-autocomplete
                                                solo
                                                dense
                                                clearable
                                                v-model="contract"
                                                :items="contracts"
                                                item-value="CustomerOrderNo"
                                                :item-text="item => item.CustomerOrderNo +' - '+ item.volume +' - '+ item.ambil"
                                                class="ma-0 pa-0 border-12"
                                                hide-details=true
                                                @change="(event) => getItemContract(event)"
                                            ></v-autocomplete>  
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="4" class="pt-0 pb-0">
                                            <h6 class="blue-lcd mb-1">Contract Expiration Date</h6> 
                                            <v-menu
                                                ref="modal"
                                                v-model="modal_contract_expiration_date"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="auto"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                    dense
                                                    solo
                                                    v-model="contract_expiration_date"
                                                    persistent-hint
                                                    append-icon="mdi-calendar"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    class="ma-0 pa-0 border-12"
                                                    hide-details=true
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    v-model="contract_expiration_date"
                                                    no-title
                                                    @input="modal_contract_expiration_date = false"
                                                ></v-date-picker>
                                            </v-menu> 
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="4" class="pt-0 pb-0">
                                            <h6 class="blue-lcd mb-1">Broker</h6>   
                                            <v-autocomplete
                                                solo
                                                dense
                                                clearable
                                                v-model="broker"
                                                :items="brokers"
                                                item-value="broker_id"
                                                item-text="namabroker"
                                                class="ma-0 pa-0 border-12"
                                                hide-details=true
                                            ></v-autocomplete>  
                                        </v-col>
                                    </v-row>
                                    <v-divider class="mb-5"></v-divider>
                                    <v-row class="mt-5 mb-3">
                                        <v-col cols="12" xs="12" sm="6" md="1" class="pt-0 pb-0">
                                            <h6 class="blue-lcd mb-1">Allow Release</h6>   
                                            <v-autocomplete
                                                solo
                                                dense
                                                clearable
                                                v-model="allow_release"
                                                :items="allow_releases"
                                                item-value="value"
                                                item-text="label"
                                                class="ma-0 pa-0 border-12"
                                                hide-details=true
                                                :readonly="readonly_allow_release"
                                            ></v-autocomplete>  
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="1" class="pt-0 pb-0">
                                            <h6 class="blue-lcd mb-1">Allow Ship</h6>   
                                            <v-autocomplete
                                                solo
                                                dense
                                                clearable
                                                v-model="allow_ship"
                                                :items="allow_ships"
                                                item-value="value"
                                                item-text="label"
                                                class="ma-0 pa-0 border-12"
                                                hide-details=true
                                                :disabled="readonly_allow_ship"
                                            ></v-autocomplete>  
                                        </v-col>
                                        <v-col class="col-12" sm="6" md="10">
                                            <div class="d-flex mt-5">
                                                <v-spacer></v-spacer>
                                                <v-menu
                                                    top
                                                    :offset-y="true"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            color="primary"
                                                            class="mr-2"
                                                        >
                                                        Instruction
                                                        <v-icon>
                                                            mdi-chevron-up
                                                        </v-icon>
                                                        </v-btn>
                                                    </template>

                                                    <v-list dense>
                                                        <v-list-item @click="dialogSpecialInstruction = true">
                                                            <v-list-item-icon>
                                                                <v-icon>mdi-printer-eye</v-icon>
                                                            </v-list-item-icon>
                                                            <v-list-item-content>
                                                                <v-list-item-title>Special Instruction</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item @click="dialogShipmentInstruction = true">
                                                            <v-list-item-icon>
                                                                <v-icon>mdi-printer-eye</v-icon>
                                                            </v-list-item-icon>
                                                            <v-list-item-content>
                                                                <v-list-item-title>Shipment Instruction</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item @click="dialogOverdueRemark = true">
                                                            <v-list-item-icon>
                                                                <v-icon>mdi-magnify</v-icon>
                                                            </v-list-item-icon>
                                                            <v-list-item-content>
                                                                <v-list-item-title>Overdue Remark</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <!-- <v-list-item>
                                                            <v-list-item-icon>
                                                                <v-icon>mdi-toggle-switch-outline</v-icon>
                                                            </v-list-item-icon>
                                                            <v-list-item-content>
                                                                <v-list-item-title>Change SKDBN</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item> -->
                                                    </v-list>
                                                </v-menu> 
                                                <!-- <v-menu
                                                    top
                                                    :offset-y="true"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            color="success"
                                                        >
                                                        Preview
                                                        <v-icon>
                                                            mdi-chevron-up
                                                        </v-icon>
                                                        </v-btn>
                                                    </template>

                                                    <v-list dense>
                                                        <v-list-item>
                                                            <v-list-item-icon>
                                                                <v-icon>mdi-printer-eye</v-icon>
                                                            </v-list-item-icon>
                                                            <v-list-item-content>
                                                                <v-list-item-title>Preview Customer SP</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item>
                                                            <v-list-item-icon>
                                                                <v-icon>mdi-printer-eye</v-icon>
                                                            </v-list-item-icon>
                                                            <v-list-item-content>
                                                                <v-list-item-title>Preview Internal SP</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item>
                                                            <v-list-item-icon>
                                                                <v-icon>mdi-toggle-switch-outline</v-icon>
                                                            </v-list-item-icon>
                                                            <v-list-item-content>
                                                                <v-list-item-title>Change SKDBN</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>  -->
                                            </div>                                         
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                        </v-card>
                        <v-dialog
                            v-model="dialogCreateNikNpwp"
                            max-width="800px"
                            transition="fade-transition"
                        >
                            <v-card class="rounded-l elevation-5 p-3">
                                <v-card-text>
                                    <v-container fluid fluid>                                            
                                        <v-row>
                                            <v-col class="col-12" sm="6" md="12">
                                                <div class="d-flex">
                                                    <div>
                                                        <h5 class="blue-lcd">
                                                            Referensi NIK / NPWP
                                                        </h5>
                                                        <p style="font-size:12px">
                                                            * Field are required. Please fill your fileds and submit for save
                                                        </p>
                                                    </div>
                                                    <v-spacer></v-spacer>
                                                    <div class="mr-3">
                                                        <h6 class="blue-lcd mb-1">Customer ID</h6>  
                                                        <v-text-field
                                                            solo
                                                            dense
                                                            persistent-hint
                                                            v-model="customer.CustomerId"
                                                            class="ma-0 pa-0 border-12"
                                                            hide-details=true
                                                            disabled
                                                        >
                                                        </v-text-field>                                                                    
                                                    </div>
                                                    <div>
                                                        <h6 class="blue-lcd mb-1">TR Type</h6>  
                                                        <v-text-field
                                                            solo
                                                            dense
                                                            persistent-hint
                                                            v-model="id_card"
                                                            class="ma-0 pa-0 border-12"
                                                            hide-details=true
                                                            disabled
                                                        >
                                                        </v-text-field>                                                                    
                                                    </div>
                                                </div>
                                                    
                                            </v-col>
                                        </v-row>
                                        <v-divider></v-divider>
                                        <v-row class="mt-5">
                                            <v-col class="col-12" sm="12" md="12">
                                                <div class="d-flex">
                                                    <h6 class="blue-lcd mt-2 mr-3">No NIK / NPWP <span class="red--text"><strong>* </strong></span></h6>  
                                                    <v-text-field
                                                        solo
                                                        dense
                                                        persistent-hint
                                                        v-model="nik_npwp"
                                                        class="ma-0 pa-0 border-12"
                                                        hide-details=true
                                                        
                                                    >
                                                    </v-text-field>
                                                </div>
                                            </v-col>
                                            <v-col class="col-12" sm="12" md="12">
                                                <div class="d-flex">
                                                    <h6 class="blue-lcd mt-2 mr-3">Nama <span class="red--text"><strong>* </strong></span></h6>  
                                                    <v-text-field
                                                        solo
                                                        dense
                                                        persistent-hint
                                                        v-model="name_nik_npwp"
                                                        class="ma-0 pa-0 border-12"
                                                        hide-details=true
                                                        
                                                    >
                                                    </v-text-field>
                                                </div>
                                            </v-col>
                                            <v-col class="col-12 mt-3" sm="12" md="12">
                                                <div class="d-flex">
                                                    <div>
                                                        <h6 class="blue-lcd">
                                                            Alamat
                                                        </h6>
                                                        <p style="font-size:12px">
                                                            * Field are required. Please fill your fileds and submit for save
                                                        </p>
                                                    </div>
                                                </div>
                                            </v-col>
                                            <v-col class="col-12" sm="12" md="12">
                                                <div class="d-flex">
                                                    <h6 class="blue-lcd mt-2 mr-3">Jalan <span class="red--text"><strong>* </strong></span></h6>  
                                                    <v-text-field
                                                        solo
                                                        dense
                                                        persistent-hint
                                                        v-model="jalan_nik_npwp"
                                                        class="ma-0 pa-0 border-12"
                                                        hide-details=true
                                                        
                                                    >
                                                    </v-text-field>
                                                </div>
                                            </v-col>
                                            <v-col class="col-12" sm="6" md="2">
                                                <h6 class="blue-lcd">Blok</h6>  
                                                <v-text-field
                                                    solo
                                                    dense
                                                    persistent-hint
                                                    v-model="blok_nik_npwp"
                                                    class="ma-0 pa-0 border-12"
                                                    hide-details=true
                                                    
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <v-col class="col-12" sm="6" md="2">
                                                <h6 class="blue-lcd">No</h6>  
                                                <v-text-field
                                                    solo
                                                    dense
                                                    persistent-hint
                                                    v-model="no_rumah_nik_npwp"
                                                    class="ma-0 pa-0 border-12"
                                                    hide-details=true
                                                    
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <v-col class="col-12" sm="6" md="2">
                                                <h6 class="blue-lcd">RT</h6>  
                                                <v-text-field
                                                    solo
                                                    dense
                                                    persistent-hint
                                                    v-model="rt_nik_npwp"
                                                    class="ma-0 pa-0 border-12"
                                                    hide-details=true
                                                    @change="formatRt()"                                                                        
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <v-col class="col-12" sm="6" md="2">
                                                <h6 class="blue-lcd">RW</h6>  
                                                <v-text-field
                                                    solo
                                                    dense
                                                    persistent-hint
                                                    v-model="rw_nik_npwp"
                                                    class="ma-0 pa-0 border-12"
                                                    hide-details=true
                                                    @change="formatRw()"
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <v-col class="col-12" sm="6" md="4">
                                                <h6 class="blue-lcd">Provinsi <span class="red--text"><strong>* </strong></span></h6>  
                                                <v-autocomplete
                                                    dense
                                                    solo
                                                    clearable
                                                    v-model="province"
                                                    :items="provinces"
                                                    item-value="province"
                                                    item-text="province"
                                                    class="ma-0 pa-0 border-12"
                                                    hide-details=true
                                                    @change="getCity()"
                                                ></v-autocomplete>  
                                            </v-col>
                                            <v-col class="col-12" sm="6" md="4">
                                                <h6 class="blue-lcd">Kota / Kab <span class="red--text"><strong>* </strong></span></h6>  
                                                <v-autocomplete
                                                    dense
                                                    solo
                                                    clearable
                                                    v-model="city"
                                                    :items="cities"
                                                    item-value="city"
                                                    item-text="city"
                                                    class="ma-0 pa-0 border-12"
                                                    hide-details=true
                                                    @change="getVillage()"
                                                ></v-autocomplete>  
                                            </v-col>
                                            <v-col class="col-12" sm="6" md="5">
                                                <h6 class="blue-lcd">Desa / Kelurahan <span class="red--text"><strong>* </strong></span></h6>  
                                                <v-autocomplete
                                                    dense
                                                    solo
                                                    clearable
                                                    v-model="subdistrict"
                                                    :items="subdistricts"
                                                    item-value="vilage"
                                                    item-text="nama_desa"
                                                    class="ma-0 pa-0 border-12"
                                                    hide-details=true
                                                    :return-object="true"
                                                    @change="getAddress()"

                                                ></v-autocomplete>  
                                            </v-col>
                                            <v-col class="col-12" sm="6" md="3">
                                                <h6 class="blue-lcd">Kode Pos</h6>  
                                                <v-text-field
                                                    solo
                                                    dense
                                                    persistent-hint
                                                    v-model="postal_code"
                                                    class="ma-0 pa-0 border-12"
                                                    hide-details=true
                                                    @change="getAddress()"
                                                    
                                                >
                                                </v-text-field>  
                                            </v-col>
                                            <v-col class="col-12" sm="12" md="12">
                                                <h6 class="blue-lcd">Nama Awal</h6>  
                                                <v-text-field
                                                    solo
                                                    dense
                                                    persistent-hint
                                                    v-model="nama_awal"
                                                    class="ma-0 pa-0 border-12"
                                                    hide-details=true
                                                    
                                                >
                                                </v-text-field>  
                                            </v-col>
                                            <v-col class="col-12" sm="12" md="12">
                                                <h6 class="blue-lcd">Alamat 1</h6>  
                                                <v-text-field
                                                    solo
                                                    dense
                                                    persistent-hint
                                                    v-model="address1"
                                                    class="ma-0 pa-0 border-12"
                                                    hide-details=true
                                                    
                                                >
                                                </v-text-field>  
                                            </v-col>
                                            <v-col class="col-12" sm="12" md="12">
                                                <h6 class="blue-lcd">Alamat 2</h6>  
                                                <v-text-field
                                                    solo
                                                    dense
                                                    persistent-hint
                                                    v-model="address2"
                                                    class="ma-0 pa-0 border-12"
                                                    hide-details=true
                                                    
                                                >
                                                </v-text-field>  
                                            </v-col>
                                        </v-row>
                                        <v-divider></v-divider>
                                        <v-row justify="center" class="mt-8">
                                            <v-col cols="12" xs="12" sm="6" md="2">
                                                <v-btn block class="rounded-l p-3 text-h6" color="#fff"  @click="dialogCreateNikNpwp = false">Batal</v-btn>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="2">
                                                <v-btn block class="rounded-l text-white p-3 text-h6" color="#0078d4" type="button" @click="addNikNpwp()">Submit</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                        <v-dialog
                            v-model="dialogDeliveryNotePicker"
                            max-width="600px"
                            transition="fade-transition"
                        >
                            <v-card class="rounded-l elevation-5 p-3">
                                <v-card-text>
                                    <v-container fluid fluid>                                            
                                        <v-row>
                                            <v-col class="col-12" sm="6" md="12">
                                                <div class="d-flex">
                                                    <div>
                                                        <h5 class="blue-lcd">
                                                            Delivery Note Picker
                                                        </h5>
                                                        <p style="font-size:12px">
                                                            * Field are required. Please fill your fileds and submit for save
                                                        </p>
                                                    </div>
                                                    <v-spacer></v-spacer>
                                                </div>
                                            </v-col>
                                        </v-row>
                                        <v-divider></v-divider>
                                        <v-row class="mt-2">
                                            <v-col class="col-12" sm="12" md="6">
                                                <h6 class="blue-lcd mb-1">NIK <span class="red--text"><strong>* </strong></span></h6>  
                                                <v-text-field
                                                    solo
                                                    dense
                                                    persistent-hint
                                                    v-model="nik_delivery_note"
                                                    class="ma-0 pa-0 border-12"
                                                    hide-details=true
                                                >
                                                </v-text-field> 
                                            </v-col>  
                                            <v-col class="col-12" sm="12" md="6">
                                                <h6 class="blue-lcd mb-1">Driver Name <span class="red--text"><strong>* </strong></span></h6>  
                                                <v-text-field
                                                    solo
                                                    dense
                                                    persistent-hint
                                                    v-model="driver_name_delivery_note"
                                                    class="ma-0 pa-0 border-12"
                                                    hide-details=true
                                                >
                                                </v-text-field> 
                                            </v-col>  
                                            <v-col class="col-12" sm="12" md="12">
                                                <h6 class="blue-lcd mb-1">Expedisi</h6>  
                                                <v-text-field
                                                    solo
                                                    dense
                                                    persistent-hint
                                                    v-model="expedisi_delivery_note"
                                                    class="ma-0 pa-0 border-12"
                                                    hide-details=true
                                                >
                                                </v-text-field> 
                                            </v-col>  
                                            <v-col class="col-12" sm="12" md="6">
                                                <h6 class="blue-lcd mb-1">Vehicle ID</h6>  
                                                <v-text-field
                                                    solo
                                                    dense
                                                    persistent-hint
                                                    v-model="vehicle_delivery_note"
                                                    class="ma-0 pa-0 border-12"
                                                    hide-details=true
                                                >
                                                </v-text-field> 
                                            </v-col> 
                                            <v-col class="col-12" sm="12" md="6">
                                                <h6 class="blue-lcd mb-1">Phone</h6>  
                                                <v-text-field
                                                    solo
                                                    dense
                                                    persistent-hint
                                                    v-model="phone_delivery_note"
                                                    class="ma-0 pa-0 border-12"
                                                    hide-details=true
                                                >
                                                </v-text-field> 
                                            </v-col> 
                                        </v-row>
                                        <v-row justify="center" class="mt-5">
                                            <v-col cols="12" xs="12" sm="6" md="3">
                                                <v-btn block class="rounded-l text-h6" color="#fff" @click="dialogDeliveryNotePicker = false">Batal</v-btn>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="3">
                                                <v-btn block class="rounded-l text-white text-h6" color="#0078d4" type="button" @click="submitDeliveryNote()">Save</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                        <v-dialog
                            v-model="dialogSpecialInstruction"
                            max-width="600px"
                            transition="fade-transition"
                        >
                            <v-card class="rounded-l elevation-5 p-3">
                                <v-card-text>
                                    <v-container fluid fluid>                                            
                                        <v-row>
                                            <v-col class="col-12" sm="6" md="12">
                                                <div class="d-flex">
                                                    <div>
                                                        <h5 class="blue-lcd">
                                                            Special Instruction
                                                        </h5>
                                                    </div>
                                                    <v-spacer></v-spacer>
                                                </div>
                                            </v-col>
                                        </v-row>
                                        <v-divider></v-divider>
                                        <v-row class="mt-2">
                                            <v-col class="col-12" sm="12" md="12">
                                                <v-simple-table
                                                    fixed-header
                                                    class="mb-5"
                                                >
                                                    <template v-slot:default>
                                                        <thead>
                                                            <tr>
                                                                <th class="text-left">
                                                                    <h6 class="blue-lcd">Special Instruction</h6>
                                                                </th>
                                                                <th class="text-center">
                                                                    Action
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                                v-for="(special, index) in special_instructions"
                                                                :key="index"
                                                            >
                                                                <td class="text-left">{{ special }}</td>
                                                                <td class="text-center">
                                                                    <v-btn color="error" class="text-white border-12" height="40px" @click="deleteItemSpecialInstruction(special, index)">
                                                                        <v-icon>mdi-trash-can-outline</v-icon>
                                                                    </v-btn>
                                                                </td>
                                                            </tr>
                                                            <tr v-if="special_instructions.length < 4">
                                                                <td>
                                                                    <v-text-field
                                                                        solo
                                                                        persistent-hint
                                                                        v-model="special_instruction"
                                                                        hide-details=true
                                                                        class="mt-2 mb-2 border-12"
                                                                    ></v-text-field>
                                                                </td>
                                                                <td class="text-center">
                                                                    <v-btn color="green" class="text-white border-12" height="40px" @click="addItemSpecialInstruction()">
                                                                        <v-icon>mdi-plus</v-icon>
                                                                    </v-btn>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                            </v-col>  
                                        </v-row>
                                        <v-row justify="center" class="mt-5">
                                            <v-col cols="12" xs="12" sm="6" md="3">
                                                <v-btn block class="rounded-l text-h6" color="#fff" @click="dialogSpecialInstruction = false">Batal</v-btn>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="3">
                                                <v-btn block class="rounded-l text-white text-h6" color="#0078d4" type="button" @click="dialogSpecialInstruction = false">Save</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                        <v-dialog
                            v-model="dialogShipmentInstruction"
                            max-width="600px"
                            transition="fade-transition"
                        >
                            <v-card class="rounded-l elevation-5 p-3">
                                <v-card-text>
                                    <v-container fluid fluid>                                            
                                        <v-row>
                                            <v-col class="col-12" sm="6" md="12">
                                                <div class="d-flex">
                                                    <div>
                                                        <h5 class="blue-lcd">
                                                            Shipment Instruction
                                                        </h5>
                                                    </div>
                                                    <v-spacer></v-spacer>
                                                </div>
                                            </v-col>
                                        </v-row>
                                        <v-divider></v-divider>
                                        <v-row class="mt-2">
                                            <v-col class="col-12" sm="12" md="12">
                                                <v-simple-table
                                                    fixed-header
                                                    class="mb-5"
                                                >
                                                    <template v-slot:default>
                                                        <thead>
                                                            <tr>
                                                                <th class="text-left">
                                                                    <h6 class="blue-lcd">Nama / Telepon Penerima</h6>
                                                                </th>
                                                                <th class="text-center">
                                                                    Action
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                                v-for="(special, index) in shipment_instructions"
                                                                :key="index"
                                                            >
                                                                <td class="text-left">{{ special }}</td>
                                                                <td class="text-center">
                                                                    <v-btn color="error" class="text-white border-12" height="40px" @click="deleteItemShipmentInstruction(special, index)">
                                                                        <v-icon>mdi-trash-can-outline</v-icon>
                                                                    </v-btn>
                                                                </td>
                                                            </tr>
                                                            <tr v-if="shipment_instructions.length < 4">
                                                                <td>
                                                                    <v-text-field
                                                                        solo
                                                                        persistent-hint
                                                                        v-model="shipment_instruction"
                                                                        hide-details=true
                                                                        class="mt-2 mb-2 border-12"
                                                                    ></v-text-field>
                                                                </td>
                                                                <td class="text-center">
                                                                    <v-btn color="green" class="text-white border-12" height="40px" @click="addItemShipmentInstruction()">
                                                                        <v-icon>mdi-plus</v-icon>
                                                                    </v-btn>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                            </v-col>  
                                        </v-row>
                                        <v-row justify="center" class="mt-5">
                                            <v-col cols="12" xs="12" sm="6" md="3">
                                                <v-btn block class="rounded-l text-h6" color="#fff" @click="dialogShipmentInstruction = false">Batal</v-btn>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="3">
                                                <v-btn block class="rounded-l text-white text-h6" color="#0078d4" type="button" @click="dialogShipmentInstruction = false">Save</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                        <v-dialog
                            v-model="dialogOverdueRemark"
                            max-width="600px"
                            transition="fade-transition"
                        >
                            <v-card class="rounded-l elevation-5 p-3">
                                <v-card-text>
                                    <v-container fluid fluid>                                            
                                        <v-row>
                                            <v-col class="col-12" sm="6" md="12">
                                                <div class="d-flex">
                                                    <div>
                                                        <h5 class="blue-lcd">
                                                            Overdue Remark
                                                        </h5>
                                                    </div>
                                                    <v-spacer></v-spacer>
                                                </div>
                                            </v-col>
                                        </v-row>
                                        <v-divider></v-divider>
                                        <v-row class="mt-2">
                                            <v-col class="col-12" sm="12" md="12">
                                                <h6 class="blue-lcd mb-1">Overdue Remark</h6> 
                                                <v-textarea
                                                    solo
                                                    class="ma-0 pa-0 border-12"
                                                    hide-details=true
                                                ></v-textarea>
                                            </v-col>  
                                        </v-row>
                                        <v-row justify="center" class="mt-5">
                                            <v-col cols="12" xs="12" sm="6" md="3">
                                                <v-btn block class="rounded-l text-h6" color="#fff" @click="dialogOverdueRemark = false">Batal</v-btn>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="3">
                                                <v-btn block class="rounded-l text-white text-h6" color="#0078d4" type="button" @click="dialogOverdueRemark = false">Save</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </v-tab-item>
                    <v-tab-item
                        value="tab-2"
                    >
                        <v-card class="rounded-xl m-1 elevation-2">
                            <v-card-text>
                                <v-container fluid>
                                    <v-row>
                                        <v-col class="col-12" sm="6" md="12">
                                            <div class="d-flex">
                                                <div class="d-flex">
                                                    <p class="mt-2">
                                                        * Field are required. Please fill your fileds and submit for save
                                                    </p>
                                                </div>
                                                <v-spacer></v-spacer>
                                                <div>
                                                    <v-btn
                                                    small
                                                    color="success"
                                                    class="py-5"
                                                    @click="dialogItem = true"
                                                    >
                                                        <v-icon>mdi-plus</v-icon>
                                                        Create New Item
                                                    </v-btn>
                                                </div>
                                            </div>
                                            <v-dialog
                                                v-model="dialogItem"
                                                persistent
                                                max-width="1200px"
                                            >
                                                <v-card class="rounded-l elevation-5 p-3">
                                                    <v-card-text>
                                                        <v-container fluid fluid>                                            
                                                            <v-row>
                                                                <v-col class="col-12" sm="6" md="12">
                                                                    <div class="d-flex">
                                                                        <div>
                                                                            <h5 class="blue-lcd">
                                                                                Order Item
                                                                            </h5>
                                                                            <p>
                                                                                * Field are required. Please fill your fileds and submit for save
                                                                            </p>
                                                                        </div>
                                                                        <v-spacer></v-spacer>
                                                                        <div>
                                                                            <h6 class="blue-lcd mb-1">Order <span class="red--text"><strong>* </strong></span></h6>   
                                                                            <v-radio-group
                                                                                v-model="stock"
                                                                                row
                                                                            >
                                                                                <v-radio
                                                                                    label="Make for Order"
                                                                                    value="order"
                                                                                ></v-radio>
                                                                                <v-radio
                                                                                    label="Taken from Stock"
                                                                                    value="stock"
                                                                                ></v-radio>
                                                                            </v-radio-group>
                                                                        </div>
                                                                    </div>
                                                                        
                                                                </v-col>
                                                            </v-row>
                                                            <v-divider class="mt-1 mb-0"></v-divider>
                                                            <v-row class="mt-3">
                                                                <v-col cols="12" xs="12" sm="3" md="3" class="pt-0 pb-0">
                                                                    <h6 class="blue-lcd mb-0 pb-0">Type</h6>  
                                                                    <v-radio-group
                                                                        v-model="type_product"
                                                                        row
                                                                        dense
                                                                        class="mt-1 pt-0"
                                                                        @change="(event) => getTypeProduct(event)"
                                                                    >
                                                                        <v-radio
                                                                            label="Good"
                                                                            value="good"
                                                                        ></v-radio>
                                                                        <v-radio
                                                                            label="Service"
                                                                            value="service"
                                                                        ></v-radio>
                                                                    </v-radio-group>
                                                                </v-col>
                                                                <v-col cols="12" xs="12" sm="3" md="3" class="pt-0 pb-0">
                                                                    <h6 class="blue-lcd mb-0 pb-0">Machine</h6>  
                                                                    <v-autocomplete
                                                                        solo
                                                                        dense
                                                                        clearable
                                                                        v-model="machine_type"
                                                                        :items="machine_types"
                                                                        item-value="mach_type"
                                                                        item-text="descr"
                                                                        class="ma-0 pa-0 border-12"
                                                                        hide-details=true
                                                                        @change="(event) => getGroup(event)"
                                                                    ></v-autocomplete>
                                                                </v-col>
                                                                <v-col cols="12" xs="12" sm="3" md="2" class="pt-0 pb-0">
                                                                    <h6 class="blue-lcd mb-0 pb-0">Group ID</h6>  
                                                                    <v-autocomplete
                                                                        solo
                                                                        dense
                                                                        clearable
                                                                        v-model="group_id"
                                                                        :items="group_ids"
                                                                        item-value="jenis"
                                                                        item-text="jenis"
                                                                        class="ma-0 pa-0 border-12"
                                                                        hide-details=true
                                                                        @change="(event) => getThickness(event)"
                                                                    ></v-autocomplete>
                                                                </v-col>
                                                                <v-col cols="12" xs="12" sm="3" md="2" class="pt-0 pb-0">
                                                                    <h6 class="blue-lcd mb-0 pb-0">Grade</h6>  
                                                                    <v-autocomplete
                                                                        solo
                                                                        dense
                                                                        clearable
                                                                        v-model="grade"
                                                                        :items="grades"
                                                                        item-value="str1"
                                                                        item-text="str1"
                                                                        class="ma-0 pa-0 border-12"
                                                                        hide-details=true
                                                                        @change="(event) => getProdCode(event)"
                                                                    ></v-autocomplete>
                                                                </v-col>
                                                                <v-col cols="12" xs="12" sm="3" md="2" class="pt-0 pb-0">
                                                                    <h6 class="blue-lcd mb-0 pb-0">Thickness</h6>  
                                                                    <v-autocomplete
                                                                        solo
                                                                        dense
                                                                        clearable
                                                                        v-model="thick"
                                                                        :items="thicks"
                                                                        item-value="Tebal"
                                                                        :item-text="item => $store.getters.convertToCurrencyUs(item.Tebal)"
                                                                        class="ma-0 pa-0 border-12"
                                                                        hide-details=true
                                                                        @change="(event) => getLebar(event)"
                                                                    ></v-autocomplete>
                                                                </v-col>
                                                                <v-col cols="12" xs="12" sm="3" md="2" class="pt-0 pb-0">
                                                                    <h6 class="blue-lcd mb-0 pb-0">Width</h6>  
                                                                    <v-autocomplete
                                                                        solo
                                                                        dense
                                                                        clearable
                                                                        v-model="width"
                                                                        :items="widths"
                                                                        item-value="Lebar"
                                                                        :item-text="item => $store.getters.convertToCurrencyUs(item.Lebar)"
                                                                        class="ma-0 pa-0 border-12"
                                                                        hide-details=true
                                                                        suffix="mm"
                                                                        @change="(event) => getColor(event)"
                                                                    ></v-autocomplete>
                                                                </v-col>
                                                                <v-col cols="12" xs="12" sm="3" md="2" class="pt-0 pb-0">
                                                                    <h6 class="blue-lcd mb-0 pb-0">Color ID</h6>  
                                                                    <v-autocomplete
                                                                        solo
                                                                        dense
                                                                        clearable
                                                                        v-model="color"
                                                                        :items="colors"
                                                                        item-value="warna"
                                                                        item-text="namawarna"
                                                                        class="ma-0 pa-0 border-12"
                                                                        hide-details=true
                                                                        @change="(event) => getProdCode(event)"
                                                                    ></v-autocomplete>
                                                                </v-col>
                                                                <v-col cols="12" xs="12" sm="3" md="2" class="pt-0 pb-0">
                                                                    <h6 class="blue-lcd mb-0 pb-0">Brand</h6>  
                                                                    <v-autocomplete
                                                                        solo
                                                                        dense
                                                                        clearable
                                                                        v-model="brand"
                                                                        :items="brands"
                                                                        item-value="brand_id"
                                                                        item-text="brand_id"
                                                                        class="ma-0 pa-0 border-12"
                                                                        hide-details=true
                                                                        @change="(event) => getProdCode(event)"
                                                                    ></v-autocomplete>
                                                                </v-col>
                                                                <v-col cols="12" xs="12" sm="3" md="2" class="pt-0 pb-0">
                                                                    <h6 class="blue-lcd mb-0 pb-0">Add ID</h6>  
                                                                    <v-autocomplete
                                                                        solo
                                                                        dense
                                                                        clearable
                                                                        v-model="add_id"
                                                                        :items="add_ids"
                                                                        item-value="add_id"
                                                                        item-text="descr"
                                                                        class="ma-0 pa-0 border-12"
                                                                        hide-details=true
                                                                        @change="(event) => getProdCode(event)"
                                                                    ></v-autocomplete>
                                                                </v-col>
                                                                <v-col cols="12" xs="12" sm="3" md="2" class="pt-0 pb-0">
                                                                    <h6 class="blue-lcd mb-0 pb-0">Tora ID</h6>  
                                                                    <v-autocomplete
                                                                        solo
                                                                        dense
                                                                        clearable
                                                                        v-model="tor"
                                                                        :items="tors"
                                                                        item-value="tor_id"
                                                                        item-text="descr"
                                                                        class="ma-0 pa-0 border-12"
                                                                        hide-details=true
                                                                    ></v-autocomplete>
                                                                </v-col>
                                                                <v-col cols="12" xs="12" sm="3" md="2" class="pt-0 pb-0">
                                                                    <!-- <v-btn block class="rounded-l text-white mt-5" color="primary" @click="getProdCode()">Get Product Code</v-btn> -->
                                                                </v-col>
                                                                <v-col cols="12" xs="12" sm="6" md="6" class="pt-2 pb-0">
                                                                    <h6 class="blue-lcd">Product Code <span class="red--text"><strong>* </strong></span></h6>  
                                                                    <v-autocomplete
                                                                        solo
                                                                        dense
                                                                        clearable
                                                                        v-model="product"
                                                                        :items="products"
                                                                        item-value="barangjadiid"
                                                                        item-text="namabarang"
                                                                        class="ma-0 pa-0 border-12"
                                                                        hide-details=true
                                                                        cache-items
                                                                        return-object
                                                                        @change="(event) => getWeight(event)"
                                                                    ></v-autocomplete> 
                                                                </v-col>
                                                                <v-col cols="12" xs="12" sm="6" md="3" class="pt-3 pb-0">
                                                                    <h6 class="blue-lcd mb-1">P Sheet Genteng</h6>  
                                                                    <v-text-field
                                                                        dense
                                                                        solo
                                                                        persistent-hint
                                                                        v-model="sheet_genteng"
                                                                        class="ma-0 pa-0 border-12"
                                                                        hide-details=true
                                                                        readonly
                                                                    >
                                                                    </v-text-field>
                                                                </v-col>
                                                                <v-col cols="12" xs="12" sm="6" md="3" class="pt-3 pb-0">
                                                                    <h6 class="blue-lcd mb-1">ACP ID</h6>  
                                                                    <v-autocomplete
                                                                        solo
                                                                        dense
                                                                        clearable
                                                                        v-model="acp_id"
                                                                        :items="acp_ids"
                                                                        item-value="acp_id"
                                                                        item-text="descr"
                                                                        class="ma-0 pa-0 border-12"
                                                                        hide-details=true
                                                                    ></v-autocomplete> 
                                                                </v-col>
                                                                <!-- <v-col cols="12" xs="12" sm="6" md="3" class="pt-1 pb-0">
                                                                    <v-btn class="rounded-l text-h6 mt-6 mr-2" color="#f1f1f1">Refresh</v-btn>
                                                                    <v-btn class="rounded-l  text-white text-h6 mt-6" color="#0078d4">Search</v-btn>
                                                                </v-col> -->
                                                            </v-row>
                                                            <v-divider class="mt-5 mb-5"></v-divider>
                                                            <v-row class="mt-3">
                                                                <v-col class="col-12" sm="6" md="12">
                                                                    <div class="d-flex">
                                                                        <div>
                                                                            <h5 class="blue-lcd">
                                                                                Finish Good Detail
                                                                            </h5>
                                                                            <p>
                                                                                * Field are required. Please fill your fileds and submit for save
                                                                            </p>
                                                                        </div>
                                                                        <v-spacer></v-spacer>
                                                                        <div>
                                                                            <h6 class="blue-lcd mb-1">Retail <span class="red--text"><strong>* </strong></span></h6>   
                                                                            <v-radio-group
                                                                                v-model="retail"
                                                                                row
                                                                            >
                                                                                <v-radio
                                                                                    label="Non Retail"
                                                                                    value="non_retail"
                                                                                ></v-radio>
                                                                                <v-radio
                                                                                    label="Retail"
                                                                                    value="retail"
                                                                                ></v-radio>
                                                                            </v-radio-group>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </v-col>
                                                            </v-row>
                                                            <v-row class="mb-1" v-show="alert_length">
                                                                <v-col cols="12">
                                                                    <div>
                                                                        <v-alert
                                                                        outlined
                                                                        type="warning"
                                                                        prominent
                                                                        border="left"
                                                                        class="mb-1"
                                                                        >
                                                                        Please check Length
                                                                        </v-alert>
                                                                    </div>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row class="mb-1" v-show="alert_price_minimum">
                                                                <v-col cols="12">
                                                                    <div>
                                                                        <v-alert
                                                                        outlined
                                                                        type="error"
                                                                        prominent
                                                                        border="left"
                                                                        class="mb-1"
                                                                        >
                                                                        {{price_minimum}}
                                                                        </v-alert>
                                                                    </div>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row class="mt-3">
                                                                <v-col class="col-12 pt-0 pb-0" sm="6" md="2">
                                                                    <h6 class="blue-lcd mb-1">Length <span class="red--text"><strong>* </strong></span></h6>
                                                                    <div class="v-input ma-0 pa-0 v-input--dense border-12 theme--light v-text-field v-text-field--single-line v-text-field--solo
                                                                    dense v-text-field--is-booted v-text-field--enclosed">
                                                                        <div class="v-input__control">
                                                                            <div class="v-input__slot">
                                                                                <div class="v-text-field__slot">
                                                                                    <money class="" name="length" v-model="length" v-bind="money" @change.native="calculateLuas($event)"></money>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </v-col>
                                                                <v-col class="col-12 pt-0 pb-0" sm="6" md="2">
                                                                    <h6 class="blue-lcd mb-1">Qty <span class="red--text"><strong>* </strong></span></h6>
                                                                    <div class="v-input ma-0 pa-0 v-input--dense border-12 theme--light v-text-field v-text-field--single-line v-text-field--solo
                                                                    dense v-text-field--is-booted v-text-field--enclosed">
                                                                        <div class="v-input__control">
                                                                            <div class="v-input__slot">
                                                                                <div class="v-text-field__slot">
                                                                                    <money class="" name="length" v-model="quantity" v-bind="money" @change.native="calculateLuas($event)"></money>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </v-col>
                                                                <v-col class="col-12 pt-0 pb-1" sm="6" md="2">
                                                                    <h6 class="blue-lcd mb-1">Luas</h6>
                                                                    <v-text-field
                                                                        solo
                                                                        dense
                                                                        persistent-hint
                                                                        v-model="luas"
                                                                        class="ma-0 pa-0 border-12"
                                                                        suffix="m"
                                                                        hide-details=true
                                                                        readonly
                                                                    >
                                                                    </v-text-field> 
                                                                </v-col>
                                                                <v-col class="col-12 pt-0 pb-1" sm="6" md="2">
                                                                    <h6 class="blue-lcd mb-1">Weight</h6>
                                                                    <v-text-field
                                                                        dense
                                                                        solo
                                                                        persistent-hint
                                                                        v-model="weight"
                                                                        class="ma-0 pa-0 border-12"
                                                                        suffix="Kg"
                                                                        hide-details=true
                                                                        readonly
                                                                    >
                                                                    </v-text-field> 
                                                                </v-col>
                                                                <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0">
                                                                    <h6 class="blue-lcd mb-1">Kontrak</h6>  
                                                                    <v-text-field
                                                                        dense
                                                                        solo
                                                                        persistent-hint
                                                                        v-model="contract"
                                                                        class="ma-0 pa-0 border-12"
                                                                        hide-details=true
                                                                        readonly
                                                                    >
                                                                    </v-text-field>
                                                                </v-col>
                                                                <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0">
                                                                    <h6 class="blue-lcd mb-1">Item Kontrak</h6>  
                                                                    <v-autocomplete
                                                                        solo
                                                                        dense
                                                                        clearable
                                                                        v-model="item_kontrak"
                                                                        :items="item_kontraks"
                                                                        item-value="item_num"
                                                                        :item-text="item => item.barangjadiid +' - '+ item.namabarang +' - '+ item.lkalip"
                                                                        class="ma-0 pa-0 border-12"
                                                                        hide-details=true
                                                                    ></v-autocomplete>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row class="mt-6">
                                                                <v-col cols="12" xs="12" sm="6" md="2" class="pt-0 pb-1">
                                                                    <h6 class="blue-lcd">Price <span class="red--text"><strong>* </strong></span></h6>   
                                                                    <v-radio-group
                                                                        v-model="price_satuan"
                                                                        row
                                                                        hide-details=true
                                                                        dense
                                                                        class="mt-0 pt-2"
                                                                        @change="(event) => calculatePriceSelect(event)"
                                                                    >
                                                                        <v-radio
                                                                            label="/m"
                                                                            value="meter"
                                                                        ></v-radio>
                                                                        <v-radio
                                                                            label="/unit"
                                                                            value="unit"
                                                                        ></v-radio>
                                                                    </v-radio-group>
                                                                </v-col>
                                                                <v-col class="col-12 pt-0 pb-0" sm="6" md="3">
                                                                    <h6 class="blue-lcd mb-1">Harga <span class="red--text"><strong>* </strong></span></h6>
                                                                    <div class="v-input ma-0 pa-0 v-input--dense border-12 theme--light v-text-field v-text-field--single-line v-text-field--solo
                                                                    dense v-text-field--is-booted v-text-field--enclosed">
                                                                        <div class="v-input__control">
                                                                            <div class="v-input__slot">
                                                                                <div class="v-text-field__slot">
                                                                                    <money class="" name="price" v-model.lazy="price" v-bind="money" @change.native="calculatePrice($event)"></money>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </v-col>
                                                                <!-- <v-col class="col-12 pt-0 pb-0" sm="6" md="3">
                                                                    <h6 class="blue-lcd mb-1">Total Harga <span class="red--text"><strong>* </strong></span></h6>
                                                                    <v-text-field
                                                                        solo
                                                                        dense
                                                                        persistent-hint
                                                                        readonly
                                                                        v-model="total_price"
                                                                        class="ma-0 pa-0 border-12"
                                                                        hide-details=true
                                                                    >
                                                                    </v-text-field> 
                                                                </v-col> -->
                                                                <v-col class="col-12 pt-0 pb-0" sm="6" md="3">
                                                                    <h6 class="blue-lcd mb-1">Total Harga <span class="red--text"><strong>* </strong></span></h6>
                                                                    <div class="v-input ma-0 pa-0 v-input--dense border-12 theme--light v-text-field v-text-field--single-line v-text-field--solo
                                                                    dense v-text-field--is-booted v-text-field--enclosed">
                                                                        <div class="v-input__control">
                                                                            <div class="v-input__slot">
                                                                                <div class="v-text-field__slot">
                                                                                    <money class="" name="total_price" v-model.lazy="total_price" v-bind="money" @change.native="calculatePriceTotal($event)"></money>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </v-col>
                                                                <v-col class="col-12 pt-0 pb-0" sm="6" md="2">
                                                                    <h6 class="blue-lcd mb-1">Diskon Unit</h6>
                                                                    <div class="v-input ma-0 pa-0 v-input--dense border-12 theme--light v-text-field v-text-field--single-line v-text-field--solo
                                                                    dense v-text-field--is-booted v-text-field--enclosed">
                                                                        <div class="v-input__control">
                                                                            <div class="v-input__slot">
                                                                                <div class="v-text-field__slot">
                                                                                    <money class="" name="total_price" v-model="discount_price" v-bind="money" @change.native="calculatePrice($event)"></money>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </v-col>
                                                                <v-col class="col-12 pt-0 pb-0" sm="6" md="2">
                                                                    <h6 class="blue-lcd mb-1">Total Harga</h6>
                                                                    <div class="v-input ma-0 pa-0 v-input--dense border-12 theme--light v-text-field v-text-field--single-line v-text-field--solo
                                                                    dense v-text-field--is-booted v-text-field--enclosed">
                                                                        <div class="v-input__control">
                                                                            <div class="v-input__slot">
                                                                                <div class="v-text-field__slot">
                                                                                    <money class="" name="total_price" v-model="sum_total_price" v-bind="money" v-bind:readonly="true"></money>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </v-col>
                                                                <v-col class="col-12 pt-0 pb-0" sm="6" md="3">
                                                                    <h6 class="blue-lcd mb-1">Selisih Harga Memo</h6>
                                                                    <v-text-field
                                                                        solo
                                                                        dense
                                                                        persistent-hint
                                                                        v-model="selisih_harga_memo"
                                                                        class="ma-0 pa-0 border-12"
                                                                        suffix="/m"
                                                                        hide-details=true
                                                                    >
                                                                    </v-text-field> 
                                                                </v-col>
                                                                <v-col class="col-12 pt-0 pb-0" sm="6" md="3">
                                                                    <h6 class="blue-lcd mb-1">Fee Luar</h6>
                                                                    <v-text-field
                                                                        solo
                                                                        dense
                                                                        persistent-hint
                                                                        v-model="fee_luar"
                                                                        class="ma-0 pa-0 border-12"
                                                                        suffix="/m"
                                                                        hide-details=true
                                                                    >
                                                                    </v-text-field> 
                                                                </v-col>
                                                                <v-col class="col-12 pt-0 pb-0" sm="6" md="6">
                                                                    <h6 class="blue-lcd mb-1">Remark</h6>
                                                                    <v-text-field
                                                                        solo
                                                                        dense
                                                                        persistent-hint
                                                                        v-model="remark_item"
                                                                        class="ma-0 pa-0 border-12"
                                                                        hide-details=true
                                                                    >
                                                                    </v-text-field> 
                                                                </v-col>
                                                                <v-col cols="12" xs="12" sm="6" md="6" class="pt-1 pb-0">
                                                                    <h6 class="blue-lcd">Packaging <span class="red--text"><strong>* </strong></span></h6>   
                                                                    <v-radio-group
                                                                        v-model="packaging"
                                                                        row
                                                                        class="mt-0 pt-0"
                                                                    >
                                                                        <v-radio
                                                                            label="No Packing"
                                                                            value="no_packing"
                                                                        ></v-radio>
                                                                        <v-radio
                                                                            label="Sederhana"
                                                                            value="sederhana"
                                                                        ></v-radio>
                                                                        <v-radio
                                                                            label="Lengkap"
                                                                            value="lengkap"
                                                                        ></v-radio>
                                                                    </v-radio-group>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row justify="center" class="mt-5">
                                                                <v-col cols="12" xs="12" sm="6" md="3">
                                                                    <v-btn block class="rounded-l p-2 text-h6" color="#f1f1f1" @click="dialogItem = false">Cancel</v-btn>
                                                                </v-col>
                                                                <v-col cols="12" xs="12" sm="6" md="3">
                                                                    <v-btn block class="rounded-l text-white p-2 text-h6" color="#0078d4" @click="addProduct()" :disabled="alert_price_minimum ? true : false">Save</v-btn>
                                                                </v-col>
                                                            </v-row>
                                                        </v-container>
                                                    </v-card-text>
                                                </v-card>
                                            </v-dialog>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-simple-table
                                                fixed-header                                                             
                                            >
                                                <thead>
                                                    <tr style="border-top:1px solid rgb(216 212 212);">
                                                        <th class="text-center">
                                                            Item
                                                        </th>
                                                        <th class="text-center">
                                                            Prod Code
                                                        </th>
                                                        <th class="text-center">
                                                            Description
                                                        </th>
                                                        <th class="text-center">
                                                            Add ID
                                                        </th>
                                                        <th class="text-center">
                                                            Tor ID
                                                        </th>
                                                        <th class="text-center">
                                                            Length
                                                        </th>
                                                        <th class="text-center">
                                                            Weight
                                                        </th>
                                                        <th class="text-center">
                                                            Qty
                                                        </th>
                                                        <th class="text-right">
                                                            Price Unit
                                                        </th>
                                                        <th class="text-right">
                                                            Total Price
                                                        </th>
                                                        <th class="text-right">
                                                            Discount Price Unit
                                                        </th>
                                                        <th class="text-right">
                                                            Total
                                                        </th>
                                                        <th class="text-center">
                                                            Satuan
                                                        </th>
                                                        <th class="text-center">
                                                            
                                                        </th>                                                    
                                                    </tr>
                                                </thead>
                                                <tbody v-if="(product_items.length > 0)" style="border-bottom:1px solid rgb(216 212 212);">
                                                    <tr
                                                        v-for="(product, index) in product_items"
                                                        :key="product.item_num"
                                                        >
                                                        <td class="text-center">{{ product.item_num }}</td>
                                                        <td class="text-center">{{ product.prod_code }}</td>
                                                        <td class="text-center">{{ product.descr }}</td>
                                                        <td class="text-center">{{ product.add_id }}</td>
                                                        <td class="text-center">{{ product.tor_id }}</td>
                                                        <td class="text-center">{{ $store.getters.convertToCurrencyUs(product.length) }} m</td>
                                                        <td class="text-center">{{ $store.getters.convertToCurrencyUs(product.weight) }} Kg</td>
                                                        <td class="text-center">{{ $store.getters.convertToCurrencyUs(product.qty) }}</td>
                                                        <td class="text-right">IDR {{ $store.getters.convertToCurrencyUs(product.price) }}</td>
                                                        <td class="text-right">IDR {{ $store.getters.convertToCurrencyUs(product.total_price) }}</td>
                                                        <td class="text-right">IDR {{ $store.getters.convertToCurrencyUs(product.discount_price) }}</td>
                                                        <td class="text-right">IDR {{ $store.getters.convertToCurrencyUs(product.sum_total_price) }}</td>
                                                        <td class="text-center"> / {{ product.price_satuan }}</td>
                                                        <td>
                                                            <v-btn small class="p-1 text-white" color="red" @click="deleteItem(product, index)">
                                                                <v-icon>
                                                                    mdi-delete
                                                                </v-icon>
                                                            </v-btn>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tbody v-else style="border-bottom:1px solid rgb(216 212 212);">
                                                    <tr class="mt-12 mb-12">
                                                        <td colspan="14" class="text-center">
                                                            <v-img class="text-center mt-12  mx-auto" src="../../../../assets/logo_header.png" alt="Admin" style="max-width:500px" />
                                                            <p class="text-center mb-12">Order Item Masih Kosong !</p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </v-simple-table>
                                        </v-col>
                                    </v-row>
                                    <v-row class="mt-8" justify="end">
                                        <v-col class="col-12 pt-0 pb-0" sm="6" md="3">
                                            <div class="d-flex">
                                                <p class="blue-lcd pt-0 pb-0 mt-0 mb-0">Total Panjang :</p>   
                                                <v-spacer></v-spacer>
                                                <h6 class="red--text" style="font-weight:600">{{ $store.getters.convertToCurrencyUs(total_length) }} m</h6>
                                            </div>
                                            <div class="d-flex">
                                                <p class="blue-lcd pt-0 pb-0 mt-0 mb-0">Total Weight :</p>   
                                                <v-spacer></v-spacer>
                                                <h6 class="red--text" style="font-weight:600">{{ $store.getters.convertToCurrencyUs(total_weight) }} Kg</h6>
                                            </div>
                                            <div class="d-flex">
                                                <p class="blue-lcd pt-0 pb-0 mt-0 mb-0">Total Order :</p>   
                                                <v-spacer></v-spacer>
                                                <h6 class="red--text" style="font-weight:600">IDR {{ $store.getters.convertToCurrencyUs(total_order) }}</h6>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
        <v-row class="mt-2">
            <v-col cols="12" xs="12" sm="12" md="12">
                <v-checkbox
                    class="ml-5"
                    v-model="term"
                    label="Preview Term and Condition"
                ></v-checkbox>
            </v-col>
        </v-row>
        <v-row justify="center" class="mt-2 mb-10">
            <v-col cols="12" xs="12" sm="6" md="2">
                <v-btn block class="rounded-l p-4 text-h6" color="#fff" to="/admin/kmb/transaction/sales-order">Batal</v-btn>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="2">
                <v-btn block class="rounded-l text-white p-4 text-h6" color="#0078d4" type="button" @click="submit()" :disabled="disabled_submit">Submit</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card class="rounded-l elevation-12 mb-12">
                    <v-card-text>
                        <v-container fluid fluid>
                            <v-row>
                                <v-col class="col-12" sm="6" md="3">
                                    <h6 class="blue-lcd mb-1">Creator</h6>
                                    <v-text-field
                                        solo
                                        dense
                                        persistent-hi
                                        v-model="$store.state.user.username"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        readonly
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col class="col-12" sm="6" md="3">
                                    <h6 class="blue-lcd mb-1">Approve 1</h6>
                                    <v-text-field
                                        solo
                                        dense
                                        persistent-hi
                                        v-model="aprv1"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        readonly
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col class="col-12" sm="6" md="3">
                                    <h6 class="blue-lcd mb-1">Approve 2</h6>
                                    <v-text-field
                                        solo
                                        dense
                                        persistent-hi
                                        v-model="aprv2"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        readonly
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col class="col-12" sm="6" md="3">
                                    <h6 class="blue-lcd mb-1">Release</h6>
                                    <v-text-field
                                        solo
                                        dense
                                        persistent-hi
                                        v-model="release"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        readonly
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { required, digits, email, max, regex } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import { Money } from 'v-money'
import {backendApi} from "../../../../backend-api/backend-api-sr"

setInteractionMode('eager')

extend('digits', {
    ...digits,
    message: '{_field_} needs to be {length} digits. ({_value_})',
})

extend('required', {
    ...required,
    message: '{_field_} can not be empty',
})

extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters',
})

extend('regex', {
    ...regex,
    message: '{_field_} {_value_} does not match {regex}',
})

extend('email', {
    ...email,
    message: 'Email must be valid',
})

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        Money
    },
    data() {
        return {
            tab: 'tab-1',
            breadcumbs: [
            {
                text: 'Kencana Maju Bersama',
                disabled: false,
                href: '/admin/kmb',
                },
                {
                text: 'Transaction',
                disabled: true,
                },
                {
                text: 'Sales Order',
                disabled: false,
                href: '/admin/kmb/transaction/sales-order',
                },
                {
                text: 'Create',
                disabled: true,
                href: '/admin/kmb/transaction/sales-order/create',
                }
            ],
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            switchCredit: false,
            loading: false,
            status: 'O',
            office: '',
            offices: [],
            divisions: [],
            division: '',
            id_card: 'K',
            order_types: [
                {
                    name: 'Customer Order',
                    value: 'cust_order'
                },
                {
                    name: 'Contract Order',
                    value: 'contract_order'
                },
                {
                    name: 'Taking Contract',
                    value: 'taking_contract'
                },
                {
                    name: 'Internal Order',
                    value: 'internal_order'
                },
                {
                    name: 'Inner Use',
                    value: 'inner_use'
                },
                {
                    name: 'Stock Order',
                    value: 'stock_order'
                },
                {
                    name: 'Sample Order',
                    value: 'sample_order'
                },
                // {
                //     name: 'Special Order',
                //     value: 'special_order'
                // }
            ],
            order_type: 'cust_order',
            readonly_order_type: false,
            projects: [],
            project: '',
            modal: false,
            order_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            saleses: [],
            sales: '',
            sales_name: '',
            orders: [],
            order: '',
            rev: '',
            customers: [],
            customer: '',
            cust_name: '',
            id_cards: [],
            id_carded: '',
            id_card_nik: '',
            no_skdbn: '',
            cust_po_no: '',
            piutang_outstanding: '',
            new_order:'',
            remaining_credit: '',
            outstanding_order: '',
            outstanding_pre_order: '',
            mills: [],
            mill: '',
            modal_ship: false,
            ship_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            ship_date_now: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            ship_terms: [],
            ship_term: '',
            pay_terms: [],
            pay_term: '',
            consigns: [],
            consign: '',
            consign_name: '',
            ship_to: '',
            readonly_ship_to: false,
            shipment_provinces:[],
            shipment_province: '',
            shipment_cities: [],
            shipment_city:'',
            expedisi: '',
            address: '',
            no_telp: '',
            remark: '',
            total_packing: '',
            down_payment: '',
            contracts: [],
            contract: '',
            contract_expiration_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            readonly_contract_expiration_date: false,
            brokers:[],
            broker: '',
            allow_release: '',
            allow_releases: [
                {
                    value: 'Y',
                    label: 'Y'
                },
                {
                    value: 'N',
                    label: 'N'
                }
            ],
            readonly_allow_release: false,
            allow_ships: [
                {
                    value: 'Y',
                    label: 'Y'
                },
                {
                    value: 'N',
                    label: 'N'
                }
            ],
            allow_ship: '',
            readonly_allow_ship: false,
            stock: 'order',
            item_nums: [],
            item_num: '',
            products: [],
            product: '',
            tors: [],
            tor: '',
            sheet_gentengs:[],
            sheet_genteng: '',
            add_ids: [],
            add_id: '',
            acp_ids: [],
            acp_id: '',
            retail: 'non_retail',
            length: '',
            quantity: '',
            luas: '',
            kontraks: [],
            kontrak: '',
            item_kontraks: [],
            item_kontrak: '',
            weight: '',
            price_satuan: 'meter',
            price: '',
            total_price: '',
            discount_price: '',
            sum_total_price: '',
            selisih_harga_memo: '',
            fee_luar: '',
            remark_item: '',
            packaging: 'no_packing',
            product_items: [],
            aprv1: '',
            aprv2: '',
            release: '',
            term: false,
            money: {
				decimal: '.',
				thousands: ',',
				prefix: '',
				suffix: '',
				precision: 2,
				masked: false
			},
            dialogItem: false,
            searchCustomer: null,
            disabled_division: false,
            dialogCreateNikNpwp: false,
            name_nik_npwp: '',
            jalan_nik_npwp: '',
            blok_nik_npwp:'',
            no_rumah_nik_npwp: '',
            rt_nik_npwp: '000',
            rw_nik_npwp: '000',
            provinces: [],
            province: '',
            cities: [],
            city: [],
            subdistricts: [],
            subdistrict: '',
            postal_code: '00000',
            nama_awal: '',
            address1: '',
            address2: '',
            nik_npwp: '',
            credits: [],
            isLoading: false,
            showExpedisi: false,
            disabled_office: false,
            paytermcv: '',
            pay_term_readonly: false,
            ship_term_readonly: false,
            dialogDeliveryNotePicker: false,
            nik_delivery_note: '',
            driver_name_delivery_note: '',
            expedisi_delivery_note: '',
            vehicle_delivery_note: '',
            phone_delivery_note: '',
            dialogSpecialInstruction: false,
            special_instruction: '',
            special_instructions: [],
            dialogShipmentInstruction: false,
            shipment_instructions: [],
            shipment_instruction: '',
            dialogOverdueRemark: false,
            bank: '',
            disabled_sales: false,
            show_no_telpon_expedisi: false,
            no_telpon_expedisi: '',
            disabled_consignee: false,
            disabled_ship_to: false,
            type_product: 'good',
            machine_types: [],
            machine_type: '',
            group_ids: [],
            group_id: '',
            grades: [],
            grade: '',
            thicks: [],
            thick: '',
            widths: [],
            width: '',
            colors: [],
            color: '',
            brands: [],
            brand: '',
            addids: [],
            addid: '',
            tora_ids: [],
            tora_id: '',
            alert_length: false,
            alert_price_minimum: false,
            price_minimum: '',
            minimum_price: 0,
            ban90: '',
            alertban90: false,
            modal_contract_expiration_date: false,
            pakai: 0,
            getberat: 0,
            credit_limit: 0
        }
    },
    mounted(){
        // console.log(this.$store.state.kmb.group_id);
        this.getOffice()
        this.initialize()
        this.getDivision()
        this.getTypeOrder()
        this.getProvince()
        // this.getCity()
        // this.getVillage()
        this.getShipmentTerm()
        // this.getPayTerm()
        // this.getShipmentProvince()
        // this.getShipmentCity()
        this.getBrokers()
        // this.getContract()
        // this.getMachineType()
        this.getGrade()
        this.getBrand()
        this.getTorID()
        this.getAddID()
        this.getAcpID()
        // this.$store.dispatch('setOverlay', false)
    },
    computed: {
        total_length(){
			var total_length = 0

            for (let index = 0; index < this.product_items.length; index++) {
                total_length = total_length + parseFloat(this.product_items[index].length);
            }
            
            return (total_length | 0)
        },
        total_weight(){
            var total_weight = 0

            for (let index = 0; index < this.product_items.length; index++) {
                total_weight = total_weight + parseFloat(this.product_items[index].weight);
            }
            
            return (total_weight | 0)
        },
        total_order(){
            var total_order = 0

            for (let index = 0; index < this.product_items.length; index++) {
                total_order = total_order + parseFloat(this.product_items[index].total_price);
            }
            
            return (total_order | 0)
        },
        disabled_submit(){
            if (this.term && this.office && this.division && this.sales && this.order_type && this.customer && this.id_card && this.mill && this.ship_date && this.ship_term && this.pay_term && this.product_items.length > 0 ) {
                return false
            } else {
                return true
            }
        },
        disabled_order_item(){
            if (this.office && this.division && this.sales && this.order_type && this.customer && this.id_card && this.mill && this.ship_date && this.ship_term && this.pay_term) {
                return false
            } else {
                return true
            }
        }
    },
    methods:{
        async getTypeOrder(){
            const respVar = await backendApi.fetchCore(`/api/get_env_var_conf?entity_id=KMB&appl_id=WEBKMB&var_id=SPECIALORDER&groupid=${this.$store.state.kmb.group_id}`, null, false, false, false) 

            if (respVar.data.data != null) {
                if (respVar.data.data === 'Y') {
                    this.order_types.push(
                        {
                            name: 'Special Order',
                            value: 'special_order'
                        }
                    )
                    this.order_type = 'special_order'

                } 
            }
        },
        changeOrderType(event){
            if (event === null || event === '') {
                
            } else {
                if (event === 'internal_order' || event === 'stock_order' || event === 'sample_order') {
                    this.pay_terms = []
                    this.pay_terms.push({
                        pay_term: "INTERNAL",
                        pay_term_desc : "INTERNAL"
                    })
                    this.pay_term = 'INTERNAL'
                    this.paytermcv = 'TT 30 DAYS'
                    this.pay_term_readonly = true
                    this.allow_ship = 'Y'
                    this.readonly_allow_ship = true
                    this.allow_release = 'Y'
                    this.readonly_allow_release = true
                    this.ship_term = "FRANCO CUSTOMER"
                    this.ship_term_readonly = true
                    this.readonly_contract_expiration_date = false
                    this.contract_expiration_date = ''

                } else {
                    if(event === 'contract_order'){
                        this.getContractDuration('KDURATION')
                    } else{
                        this.readonly_contract_expiration_date = false
                        this.contract_expiration_date = ''
                    }
                    this.getPayTerm()
                    this.pay_term = ''
                    this.paytermcv = ''
                    this.pay_term_readonly = false
                    this.allow_ship = ''
                    this.readonly_allow_ship = false
                    this.readonly_allow_release = false
                    this.allow_release = ''
                    this.ship_term = ""
                    this.ship_term_readonly = false
                }
            }
        },
        async getContractDuration(key_id){
            const respData = await backendApi.fetchCore(`/v2/master/kmb/appl_constan?key_id=${key_id}`, null, false, false, false)
            if (respData.status === 200) {
                if (respData.data.length > 0) {
                    var day = new Date()
                    day.setDate(day.getDate() + parseInt(respData.data[0].int1))
                    this.contract_expiration_date = day.toISOString().substr(0, 10)
                    this.readonly_contract_expiration_date = true
                }
            }
        },
        async getOffice(){
            this.$store.dispatch('setOverlay', true)

            const respVar = await backendApi.fetchCore(`/api/get_env_var_conf?entity_id=KMB&appl_id=WEBKMB&var_id=BRANCH&groupid=${this.$store.state.kmb.group_id}`, null, false, false, false)

            this.offices = []

            if (respVar.data.data != null) {
                var respData = await backendApi.fetchCore(`/v2/master/kmb/office?office_id=${respVar.data.data}`, null, false, false, false)
                if (respData.status === 200) {
                    this.getSales()
                    this.getProjectID()

                    this.office = respVar.data.data
                    this.disabled_office = true
                }
            } else {
                var respData = await backendApi.fetchCore(`/v2/master/kmb/office`, null, false, false, false)
            }

            if (respData.status === 200) {
                this.offices = respData.data
                this.$store.dispatch('setOverlay', false)   
            }
            
        },
        async initialize(){
            const respData = await backendApi.fetchCore(`/api/kmb/transaction/sales-order`, null, false, false, false)
            if (respData.status === 200) {
                this.mills = respData.data.mill
            }
        },
        async getDivision(){
            const respData = await backendApi.fetchCore(`/v2/master/kmb/appl_constan?key_id=DIVISION`, null, false, false, false)
            if (respData.status === 200) {
                this.divisions = respData.data
            }
        },
        async getSales(event){
            if (event === null || event === '') {
                this.saleses = []
                this.sales = ''
                this.division = ''
                this.projects = []
                this.project = ''
                this.contract = ''
                this.contracts = []
            } else {
                this.saleses = []
                const respData = await backendApi.fetchCore(`/v2/master/kmb/sales?office_id=${this.office ? this.office : ''}&bank=${this.bank ? this.bank : ''}`, null, false, false, false)

                if (respData.status === 200) {
                    this.saleses = respData.data
                    this.getProjectID()
                }
            }
            
        },
        async getShowSales(){
            const respData = await backendApi.fetchCore(`/v2/master/kmb/sales/show?office_id=${this.office ? this.office : ''}&bank=${this.bank ? this.bank : ''}`, null, false, false, false)

            if (respData.status === 200) {
                this.sales = respData.data
                this.disabled_sales = true
                this.show_no_telpon_expedisi = true
            }
        },
        async getCustomer(id){
            const respData = await backendApi.fetchCore(`/v2/master/kmb/customer?customer=${id ? id : ''}`, null, false, false, false)
            this.customers = []
            if (respData.status === 200) {
                this.customers = respData.data
            }
        },
        async getNikNpwpCustomer(event){
            this.$store.dispatch('setOverlay', true)

            this.id_card_nik = ''
            this.id_cards = []
            this.id_carded = ''
            const respData = await backendApi.fetchCore(`/v2/master/kmb/customer/nik_npwp?customer=${this.customer ? this.customer.CustomerId : ''}&id_card=${this.id_card ? this.id_card : ''}`, null, false, false, false)
            if (respData.status === 200) {
                this.id_cards = respData.data
                this.$store.dispatch('setOverlay', false)

            }
        },
        getNoNikNpwp(event){
            if (event === null || event === '') {
                this.id_card_nik = ''
            } else {
                this.id_card_nik = this.id_carded ? this.id_carded.CustomerName : ''
            }
        },  
        getOrderType(order_type){
            this.order_type = order_type.value
        },
        changeApplConstanDivision(event){
            if (event === null || event === '') {
                this.division = ''
                this.projects = []
                this.project = ''
                this.contract = ''
                this.contracts = []
            } else {
                if (this.sales.division == 'R') {
                    this.division = this.sales.division
                    this.disabled_division = true
                }
                this.getProjectID()
                this.getContract()
            }
            
        },  
        async getProjectID(){
            const respData = await backendApi.fetchCore(`/v2/master/kmb/project_id?office=${this.office ? this.office : ''}&sales=${this.sales ? this.sales.SalesId : ''}`, null, false, false, false)
            this.projects = []
            if (respData.status === 200) {
                this.projects = respData.data
            }
        },
        async getProvince(){
            const respData = await backendApi.fetchCore(`/v2/master/kmb/customer/province`, null, false, false, false)
            this.provinces = []
            if (respData.status === 200) {
                this.provinces = respData.data
                this.shipment_provinces = respData.data
            }
        },
        async getShipmentProvince(){
            const respData = await backendApi.fetchCore(`/v2/master/kmb/customer/province`, null, false, false, false)
            this.shipment_provinces = []
            if (respData.status === 200) {
                this.shipment_provinces = respData.data
            }
        },
        async getCity(){
            const respData = await backendApi.fetchCore(`/v2/master/kmb/customer/city?province=${this.province ? this.province : ''}`, null, false, false, false)
            this.cities = []
            if (respData.status === 200) {
                this.cities = respData.data
                this.shipment_cities = respData.data
            }
        },
        async getShipmentCity(event){
            if (event === null || event === '') {
                this.shipment_cities = []
                this.shipment_city = ''
            } else {
                const respData = await backendApi.fetchCore(`/v2/master/kmb/customer/city?province=${this.shipment_province ? this.shipment_province : ''}`, null, false, false, false)
                this.shipment_cities = []
                if (respData.status === 200) {
                    this.shipment_cities = respData.data
                }
            }
            
        },
        async getVillage(){
            const respData = await backendApi.fetchCore(`/v2/master/kmb/customer/village?city=${this.city ? this.city : ''}`, null, false, false, false)
            this.subdistricts = []
            if (respData.status === 200) {
                this.subdistricts = respData.data
            }
        },
        async getShipmentTerm(){
            const respData = await backendApi.fetchCore(`/v2/master/kmb/ship_term`, null, false, false, false)
            this.ship_terms = []
            if (respData.status === 200) {
                this.ship_terms = respData.data
            }
        },
        async getPayTerm(){
            const respData = await backendApi.fetchCore(`/v2/master/kmb/pay_term_customer?customer=${this.customer ? this.customer.CustomerId : ''}`, null, false, false, false)
            this.pay_terms = []
            if (respData.status === 200) {
                // this.pay_terms = respData.data

                for (let i = 0; i < respData.data.length; i++) {
                    this.pay_terms.push({
                        pay_term: respData.data[i].pay_term.trim(),
                        pay_term_id: respData.data[i].pay_term_id.trim(),
                        mill_id: respData.data[i].mill_id.trim(),
                        pay_term_desc: respData.data[i].pay_term_desc.trim(),
                        duration: respData.data[i].duration,
                        num_of_days: respData.data[i].num_of_days,
                        active_flag: respData.data[i].active_flag
                    })
                }
            }
        },
        async getConsignee(event){

            if (event === null || event === '') {
                this.consigns = []
                this.consign = ''
                this.customer = ''
                this.customers = ''
                this.credits = []
                this.id_card_nik = ''
                this.id_cards = []
                this.id_carded = ''
                this.order_type = 'cust_order'
                this.readonly_order_type = false
                this.pay_term = ''
                this.pay_term_readonly = false
                this.allow_ship = ''
                this.readonly_allow_ship = false
                this.disabled_consignee = false
                this.disabled_ship_to = false
                this.ship_to = ''
                this.consign_name = ''
                this.piutang_outstanding = ''
                this.new_order = ''
                this.outstanding_order = ''
                this.outstanding_pre_order = ''
                this.remaining_credit = ''
                this.ban90 = ''
                this.alertban90 = false
                this.sales = ''
                this.disabled_sales = false
                this.bank = ''
                this.getSales()
                this.getPayTerm()
            } else {
                this.getNikNpwpCustomer()
                this.getPayTerm()
                if (event.Real_City === 'GLOBAL') {
                    this.bank = 'GLOBAL'
                    this.order_type = 'cust_order'
                    this.readonly_order_type = true
                    this.pay_term = 'CASH BEFORE DELIVERY'
                    this.pay_term_readonly = true
                    this.allow_ship = 'N'
                    this.readonly_allow_ship = true
                    if (this.office) {
                        this.getSales()
                        this.getShowSales()
                    } else{
                        this.snackbar = {
                            color: "error",
                            icon: "mdi-alert-circle",
                            mode: "multi-line",
                            position: "top",
                            timeout: 7500,
                            title: "Error",
                            text: 'Please fill office ID',
                            visible: true
                        };
                        this.consigns = []
                        this.consign = ''
                        this.customer = ''
                        this.customers = ''
                        this.credits = []
                        this.id_card_nik = ''
                        this.id_cards = []
                        this.id_carded = ''
                        this.order_type = ''
                        this.readonly_order_type = false
                        this.pay_term = ''
                        this.pay_term_readonly = false
                        this.allow_ship = ''
                        this.readonly_allow_ship = false
                        this.disabled_sales = false

                    }
                } else {
                    this.readonly_order_type = false
                    this.pay_term = ''
                    this.pay_term_readonly = false
                    this.allow_ship = ''
                    this.readonly_allow_ship = false
                    this.disabled_sales = false
                }
                
                if (event.kind_of_business === '' || event.ownership === '' || event.kota === '' || event.district === '' || event.vilage === '' || event.Telepon === '') {
                    this.snackbar = {
                        color: "error",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Error",
                        text: 'Tolong lengkapi data customer jenis usaha, kepemilikan usaha, kota, desa, kecamatan, telepon',
                        visible: true
                    };
                }

                if (event.payterm < 1) {
                    this.pay_term = 'CASH BEFORE DELIVERY'
                    this.pay_term_readonly = true
                    this.allow_ship = 'N'
                    this.readonly_allow_ship = true
                } else {
                    this.pay_term = ''
                    this.pay_term_readonly = false
                    this.allow_ship = 'Y'
                    this.readonly_allow_ship = false
                }

                this.getCreditUsedPiutang(event)
                this.getPiutang(event)

                const respData = await backendApi.fetchCore(`/v2/master/kmb/consignee?customerid=${event ? event.CustomerId : ''}`, null, false, false, false)
                this.consigns = []
                if (respData.status === 200) {
                    this.consigns = respData.data

                    if (event.kind_of_business != 'KONTRAKTOR') {
                        if (event.bank_atas_nama3 === 'N') {
                            this.disabled_consignee = true
                            this.disabled_ship_to = true
                            this.consign = respData.data[0]
                            this.ship_to = respData.data[0].cons_name + ' ' + respData.data[0].alamat + ' ' + respData.data[0].kota + ' ' + respData.data[0].propinsi
                            this.consign_name = respData.data[0].cons_name
                        } else {
                            this.disabled_consignee = false
                            this.disabled_ship_to = false
                            this.ship_to = ''
                            this.consign_name = ''
                        }
                    } else if(event.bank_atas_nama3 === 'Y'){
                        this.disabled_consignee = false
                        this.disabled_ship_to = false
                        this.ship_to = ''
                        this.consign_name = ''
                    }

                    this.getCreditUsed()
                }
            }
            
        },
        async getCreditUsedPiutang(event){
            if (event.cust_group) {
                var respData = await backendApi.fetchCore(`/v2/master/kmb/credit_used/credit_used?cust_group=${event ? event.cust_group : ''}`, null, false, false, false)
            } else {
                var respData = await backendApi.fetchCore(`/v2/master/kmb/credit_used/credit_used?customer=${event ? event.CustomerId : ''}`, null, false, false, false)
            }

            if (respData.status === 200) {
                if (respData.data.length > 0) {
                    this.piutang_outstanding = this.$store.getters.convertToCurrencyUs(respData.data[0].piutang)
                    var new_order = parseFloat(respData.data[0].neword) - parseFloat(respData.data[0].kwi)
                    this.new_order = new_order < 0 ? 0 : this.$store.getters.convertToCurrencyUs(new_order)
                    this.outstanding_order = this.$store.getters.convertToCurrencyUs(respData.data[0].outstandingneword)
                    this.outstanding_pre_order = this.$store.getters.convertToCurrencyUs(respData.data[0].preorder)

                    if (event.cust_group) {
                        this.credit_limit = parseFloat(respData.data[0].credit_limit_group)
                        var remaining_credit = ( parseFloat(respData.data[0].credit_limit_group) + parseFloat(respData.data[0].kwi) ) - parseFloat(respData.data[0].neword) - parseFloat(respData.data[0].outstandingneword) - parseFloat(respData.data[0].piutang) - parseFloat(respData.data[0].preorder)
                    } else {
                        this.credit_limit = parseFloat(respData.data[0].credit_limit)
                        var remaining_credit = ( parseFloat(respData.data[0].credit_limit) + parseFloat(respData.data[0].kwi) ) - parseFloat(respData.data[0].neword) - parseFloat(respData.data[0].outstandingneword) - parseFloat(respData.data[0].piutang) - parseFloat(respData.data[0].preorder)

                        console.log(parseFloat(respData.data[0].credit_limit), parseFloat(respData.data[0].kwi), parseFloat(respData.data[0].neword), parseFloat(respData.data[0].outstandingneword) , parseFloat(respData.data[0].piutang), parseFloat(respData.data[0].preorder));

                    }


                    this.remaining_credit = this.$store.getters.convertToCurrencyUs(remaining_credit)
                }
            }

        },
        async getPiutang(event){
            const respData = await backendApi.fetchCore(`/v2/master/kmb/piutang?customer=${event ? event.CustomerId : ''}`, null, false, false, false)
            if (respData.status === 200) {
                const respDataKKA = await backendApi.fetchCore(`/v2/master/kmb/getPiutang90KKA?customer=${event ? event.CustomerId : ''}`, null, false, false, false)

                if (respDataKKA.status === 200) {
                    console.log(respData.data, respDataKKA.data);
                    var piutang90 = respData.data.piutang90
                    var piutang90Kka = respDataKKA.data.piutang90
                    if ((parseFloat(piutang90) + parseFloat(piutang90Kka)) > 0) {
                        this.snackbar = {
                            color: "error",
                            icon: "mdi-alert-circle",
                            mode: "multi-line",
                            position: "top",
                            timeout: 7500,
                            title: "Ada piutang >=90 hari sejak tgl surat jalan",
                            text: 'Maaf Tidak bisa lanjut ke approve 1, jika belum di approve 0 oleh finance',
                            visible: true
                        };
                        this.ban90 = 'Y'
                        this.alertban90 = true
                    } else {
                        this.ban90 = ''
                        this.alertban90 = false
                    }
                }
            }
        },
        monthDiff(d1, d2) {
            var months;
            months = (d2.getFullYear() - d1.getFullYear()) * 12;
            months -= d1.getMonth();
            months += d2.getMonth();
            return months <= 0 ? 0 : months;
        },
        async getBrokers(){
            const respData = await backendApi.fetchCore(`/v2/master/kmb/broker`, null, false, false, false)
            this.brokers = []
            if (respData.status === 200) {
                this.brokers = respData.data
            }
        },
        async getContract(){
            const respData = await backendApi.fetchCore(`/v2/master/kmb/contract?salesid=${this.sales ? this.sales.SalesId : ''}`, null, false, false, false)
            this.contracts = []
            if (respData.status === 200) {
                this.contracts = respData.data
            }
        },
        async getItemContract(event){
            if (event === null || event === '') {
                this.item_kontraks = []
                this.item_kontrak = ''
            } else {
                const respData = await backendApi.fetchCore(`/v2/master/kmb/item_contract?customerorderno=${event ? event : ''}`, null, false, false, false)
                this.item_kontraks = []
                if (respData.status === 200) {
                    this.item_kontraks = respData.data
                }
            }
        },
        async getCreditUsed(){
            const respData = await backendApi.fetchCore(`/v2/master/kmb/credit_used?customer=${this.customer ? this.customer.CustomerId : ''}`, null, false, false, false)
            this.credits = []
            if (respData.status === 200) {
                this.credits = respData.data
            }
        },
        formatRt(){
            if (isNaN(this.rt_nik_npwp)) {
                this.rt_nik_npwp = '000' 
            } else {
                var rt = parseInt(this.rt_nik_npwp)

                if (rt >= 10 && rt < 100) {
                    this.rt_nik_npwp = '0' + rt
                } else if(rt >= 0 && rt < 10){
                    this.rt_nik_npwp = '00' + rt
                } else if(rt > 999){
                    this.rt_nik_npwp = '000'
                }
            }
        },
        formatRw(){
            if (isNaN(this.rw_nik_npwp)) {
                this.rw_nik_npwp = '000' 
            } else {
                var rw = parseInt(this.rw_nik_npwp)

                if (rw >= 10 && rw < 100) {
                    this.rw_nik_npwp = '0' + rw
                } else if(rw >= 0 && rw < 10){
                    this.rw_nik_npwp = '00' + rw
                } else if(rw > 999){
                    this.rw_nik_npwp = '000'
                }
            }
        },
        calculate(credit){
            var total = parseFloat(credit.belumJatuhTempo) + parseFloat(credit.jatuhTempLebihSatuSampai30Hari) + parseFloat(credit.jatuhTempLebih31Sampai60Hari) + parseFloat(credit.jatuhTempLebih61Sampai90Hari) + parseFloat(credit.jatuhTempLebih90Hari)
            return total
        },  
        setConsigneeName(event){
            console.log(event);
            if (event === null || event === '') {
                this.consign_name = ''
            } else {
                this.consign_name = this.consign ? this.consign.consign : ''
                this.ship_to = event.cons_name + ' ' + event.alamat + ' ' + event.kota + ' ' + event.propinsi
            }
        },
        getAddress(){
            var rt = this.rt_nik_npwp ? this.rt_nik_npwp : '000'
            var rw = this.rw_nik_npwp ? this.rw_nik_npwp : '000'
            var blok_nik_npwp = this.blok_nik_npwp ? this.blok_nik_npwp.trim() : '-'
            var no_rumah_nik_npwp = this.no_rumah_nik_npwp ? this.no_rumah_nik_npwp.trim() : '-'
            var vilage = this.subdistrict ? this.subdistrict.vilage.trim() : '-'
            var subdistrict = this.subdistrict ? this.subdistrict.districts.trim() : '-'
            var city = this.subdistrict ? this.subdistrict.city.trim() : '-'
            var province = this.province ? this.province.trim() : '-'
            var postal_code = this.postal_code ? this.postal_code : '00000'

            var address = this.jalan_nik_npwp + ' ' + blok_nik_npwp + ' ' + no_rumah_nik_npwp + ' ' + rt + ' ' + rw + ' ' + vilage + ' ' + subdistrict + ' ' + city + ' ' + province + ' ' + postal_code

            if (address.length > 200) {
                this.address1 = address.substr(0, 200);
                this.address2 = address.substr(201, address.length);
            } else {
                this.address1 = address
            }

        },
        clearAddress(){
            this.nik_npwp = ''
            this.address1 = ''
            this.address2 = ''
            this.province = ''
            this.city = ''
            this.subdistrict = ''
            this.rt_nik_npwp = '000'
            this.rw_nik_npwp = '000'
            this.blok_nik_npwp = ''
            this.no_rumah_nik_npwp = ''
            this.postal_code = '00000'
        },
        async addNikNpwp(){

            if (this.nik_npwp == '' || this.customer == '' || this.id_card == '' || this.name_nik_npwp == '' || this.jalan_nik_npwp == '' || this.province == '' || this.city == '' || this.subdistrict == '') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Please fill your fields and submit for save',
                    visible: true
                };

                return false
                
            } else {
                this.$store.dispatch('setOverlay', true)

                var reqBody = {
                    'nik_npwp': this.nik_npwp,
                    'tr_type': this.id_card,
                    'customerid': this.customer ? this.customer.CustomerId : '',
                    'CustomerName': this.customer ? this.customer.NamaCustomer : '',
                    'address1': this.address1,
                    'address2': this.address2,
                    'province': this.province,
                    'city': this.city,
                    'district': this.subdistrict.districts,
                    'village': this.subdistrict.vilage
                }

                const respData = await backendApi.fetchCore('/v2/master/kmb/customer/store_nik_npwp', reqBody, false, false, false)
                
                if (respData.status === 200) {
                    this.clearAddress()
                    this.dialogCreateNikNpwp = false

                    this.$store.dispatch('setOverlay', false)
                    
                    this.snackbar = {
                        color: "success",
                        icon: "mdi-checkbox-marked-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Success",
                        text: "Successfully created data",
                        visible: true
                    };
                } else {
                    this.$store.dispatch('setOverlay', false)

                    this.snackbar = {
                        color: "error",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Error",
                        text: 'Internal Server Error' + respData.status,
                        visible: true
                    };
                }
            }

        },
        async showDialogNik(){
            this.dialogCreateNikNpwp = true
        },
        setExpedisi(event){
            console.log(event);
            if (event === null || event === '') {
                this.showExpedisi = false
                this.ship_to = ''
                this.readonly_ship_to = false
                this.show_no_telpon_expedisi = false
                this.disabled_consignee = false
            } else {
                if (event === 'FRANCO EXPEDISI') {
                    this.showExpedisi = true
                    this.readonly_ship_to = false
                    this.ship_to = ''
                    this.show_no_telpon_expedisi = true
                    this.disabled_consignee = false
                } else {
                    this.showExpedisi = false
                    this.readonly_ship_to = false
                    this.ship_to = ''

                    if (event === 'LOCO') {
                        this.ship_to = 'DIAMBIL SENDIRI'
                        this.readonly_ship_to = true
                        this.consign = ''
                        this.disabled_consignee = true
                        this.consign_name = ''
                    } else {
                        this.disabled_consignee = false
                    }
                }
            }
        },
        submitDeliveryNote(){
            this.dialogDeliveryNotePicker = false
        },
        addItemSpecialInstruction(){
            this.special_instructions.push(this.special_instruction);
            this.special_instruction = ''
        },
        deleteItemSpecialInstruction(special, index){
            this.special_instructions.splice(index, 1);
        },
        addItemShipmentInstruction(){
            this.shipment_instructions.push(this.shipment_instruction);
            this.shipment_instruction = ''
        },
        deleteItemShipmentInstruction(special, index){
            this.shipment_instructions.splice(index, 1);
        },
        // Order Item
        async getProdCode(){
            const respData = await backendApi.fetchCore(`/v2/master/kmb/barang_jadi/prod_code?mach_type=${this.machine_type ? this.machine_type : ''}&group=${this.group_id ? this.group_id : ''}&thick=${this.thick ? parseFloat(this.thick) : ''}&color=${this.color ? this.color : ''}&lebar=${this.width ? parseFloat(this.width) : ''}&grade=${this.grade ? this.grade : ''}&brand_id=${this.brand ? this.brand : ''}&add_id=${this.add_id ? this.add_id : ''}&jenisProd=${this.stock == 'order' ? 'P' : 'stock'}`, null, false, false, false)
            this.products = []
            if (respData.status === 200) {
                this.products = respData.data
            }
        },
        getTypeProduct(event){
            console.log(event);
            if (event === 'good') {
                this.machine_type = ''
                this.getMachineType()
                this.group_id = ''
                this.thick = ''
                this.width = ''
                this.color = ''
            } else {
                this.machine_type = 'COST'
                this.machine_types = [
                    {
                        mach_type: 'COST',
                        descr: 'COST'
                    }
                ]

                this.getProdCode()

                this.group_id = ''
                this.group_ids = []
                this.thick = ''
                this.thicks = []
                this.width = ''
                this.widths = []
                this.color = ''
                this.colors = []
            }
        },
        async getMachineType(event){
            if (event === null || event === '') {
                this.machine_type = ''
                this.thick = ''
                this.thicks = []
                this.width = ''
                this.widths = []
                this.color = ''
                this.colors = []
            } else {
                const respData = await backendApi.fetchCore(`/v2/master/kmb/barang_jadi/machine_type?mill=${this.mill ? this.mill.mill_id.trim() : ''}&group=${this.group_id ? this.group_id : ''}&thick=${this.thick ? this.thick : ''}&color=${this.color ? this.color : ''}&lebar=${this.width ? this.width : ''}`, null, false, false, false)
                this.machine_types = []
                if (respData.status === 200) {
                    this.machine_types = respData.data
                }
            }
            
        },
        async getGroup(event){
            if (event === null || event === '') {
                this.machine_type = ''
                this.group_id = ''
                this.group_ids = []
                this.thick = ''
                this.thicks = []
                this.products = []
                this.product = ''
                this.color = ''
                this.colors = []
                this.width = ''
                this.widths = []

            } else {
                const respData = await backendApi.fetchCore(`/v2/master/kmb/barang_jadi/group_id?mach_type=${event ? event : ''}&thick=${this.thick ? this.thick : ''}&color=${this.color ? this.color : ''}&lebar=${this.width ? this.width : ''}`, null, false, false, false)
                this.group_ids = []
                if (respData.status === 200) {
                    this.group_ids = respData.data
                    this.getThickness()
                    this.getLebar()
                    this.getColor()
                    this.getProdCode()
                }
            }                
        },
        async getThickness(event){
            if (event === null || event === '') {
                this.group_id = ''
                this.thick = ''
                this.thicks = []
            } else {
                const respData = await backendApi.fetchCore(`/v2/master/kmb/barang_jadi/thick?mach_type=${this.machine_type ? this.machine_type : ''}&group=${this.group_id ? this.group_id : ''}&thick=${this.thick ? this.thick : ''}&color=${this.color ? this.color : ''}&lebar=${this.width ? this.width : ''}`, null, false, false, false)
                if (respData.status === 200) {
                    this.thicks = respData.data
                }

                if (event) {
                    this.getProdCode()
                }
            }
        },
        async getLebar(event){
            if (event === null || event === '') {
                this.group_id = ''
                this.thick = ''
                this.thicks = []
                this.width = ''
                this.widths = []
            } else {
                const respData = await backendApi.fetchCore(`/v2/master/kmb/barang_jadi/lebar?mach_type=${this.machine_type ? this.machine_type : ''}&group=${this.group_id ? this.group_id : ''}&thick=${this.thick ? this.thick : ''}&color=${this.color ? this.color : ''}&lebar=${this.width ? this.width : ''}`, null, false, false, false)
                if (respData.status === 200) {
                    this.widths = respData.data
                    
                }

                if (event) {
                    this.getProdCode()
                }
            }
        },
        async getColor(event){
            if (event === null || event === '') {
                this.group_id = ''
                this.thick = ''
                this.thicks = []
                this.width = ''
                this.widths = []
                this.color = ''
                this.colors = []
            } else {
                const respData = await backendApi.fetchCore(`/v2/master/kmb/barang_jadi/color?mach_type=${this.machine_type ? this.machine_type : ''}&group=${this.group_id ? this.group_id : ''}&thick=${this.thick ? this.thick : ''}&color=${this.color ? this.color : ''}&lebar=${this.width ? this.width : ''}`, null, false, false, false)
                if (respData.status === 200) {
                    this.colors = respData.data
                }
                if (event) {
                    this.getProdCode()
                }
            }
        },
        async getGrade(){
            const respData = await backendApi.fetchCore(`/v2/master/kmb/appl_constan?key_id=grd`, null, false, false, false)
            if (respData.status === 200) {
                this.grades = respData.data
            }
        },
        async getBrand(){
            const respData = await backendApi.fetchCore(`/v2/master/kmb/barang_jadi/brand`, null, false, false, false)
            if (respData.status === 200) {
                this.brands = respData.data
            }
        },
        async getTorID(){
            const respData = await backendApi.fetchCore(`/v2/master/kmb/barang_jadi/tor_id`, null, false, false, false)
            this.tors = []
            if (respData.status === 200) {
                this.tors = respData.data
            }
        },
        async getAddID(){
            const respData = await backendApi.fetchCore(`/v2/master/kmb/barang_jadi/add_id`, null, false, false, false)
            this.add_ids = []
            if (respData.status === 200) {
                this.add_ids = respData.data
            }
        },
        async getAcpID(){
            const respData = await backendApi.fetchCore(`/v2/master/kmb/barang_jadi/acp_id`, null, false, false, false)
            this.acp_ids = []
            if (respData.status === 200) {
                this.acp_ids = respData.data
            }
        },
        getWeight(event){
            if (event === null || event === '') {
                this.weight = ''
                this.product = '' 
                this.thick = ''
                this.width = ''
                this.color = ''
                this.brand = ''
                this.add_id = ''
                this.tor = ''
            } else {
                this.weight = parseFloat(event.wgt_unit)
            }
        },
        calculateLuas(event){
            var length = this.length ? parseFloat(this.length) : 0
            var qty = this.quantity ? parseInt(this.quantity) : 0

            this.luas = length * qty

            if (length < 1.8) {
                this.alert_length = true
            } else {
                this.alert_length = false
            }

            if (event) {
                if (event.isTrusted === true) {
                    if (this.product === '') {
                        this.snackbar = {
                            color: "error",
                            icon: "mdi-alert-circle",
                            mode: "multi-line",
                            position: "top",
                            timeout: 7500,
                            title: "Error",
                            text: 'Please Fill Product Code',
                            visible: true
                        };
                    } else {
                        var length = this.length ? parseFloat(this.length) : 0
                        var qty = this.quantity ? parseInt(this.quantity) : 0
                        var price = this.price ? parseFloat(this.price) : 0
                        var discount_price = this.discount_price ? parseFloat(this.discount_price) : 0
                        var total_price = 0

                        if (this.price_satuan === 'meter') {
                            total_price = length * qty * price
                        } else if(this.price_satuan === 'unit') {
                            total_price = qty * price
                        } else{
                            this.price = 0
                        }

                        this.sum_total_price = total_price - discount_price

                        this.total_price = total_price

                        this.getPriceMinimum()
                    }
                } 
            }
                
        },  
        calculatePriceSelect(event){
            var length = this.length ? parseFloat(this.length) : 0
            var qty = this.quantity ? parseInt(this.quantity) : 0
            var price = this.price ? parseFloat(this.price) : 0
            var discount_price = this.discount_price ? parseFloat(this.discount_price) : 0
            var total_price = 0

            if (this.price_satuan === 'meter') {
                total_price = length * qty * price
            } else if(this.price_satuan === 'unit') {
                total_price = qty * price
            } else{
                this.price = 0
            }

            if (event) {
                this.sum_total_price = total_price - discount_price

                this.total_price = total_price
            }
        },
        calculatePrice(event){
            var length = this.length ? parseFloat(this.length) : 0
            var qty = this.quantity ? parseInt(this.quantity) : 0
            var price = this.price ? parseFloat(this.price) : 0
            var discount_price = this.discount_price ? parseFloat(this.discount_price) : 0
            var total_price = 0

            if (this.price_satuan === 'meter') {
                total_price = length * qty * price
            } else if(this.price_satuan === 'unit') {
                total_price = qty * price
            } else{
                this.price = 0
            }

            if (event) {
                if (event.isTrusted === true) {
                    this.sum_total_price = total_price - discount_price

                    this.total_price = total_price

                    if (this.product === '') {
                        this.snackbar = {
                            color: "error",
                            icon: "mdi-alert-circle",
                            mode: "multi-line",
                            position: "top",
                            timeout: 7500,
                            title: "Error",
                            text: 'Please Fill Product Code',
                            visible: true
                        };
                    } else {
                        this.getPriceMinimum()
                    }
                }
            }
        },
        async calculatePriceTotal(event){
            var length = this.length ? parseFloat(this.length) : 0
            var qty = this.quantity ? parseInt(this.quantity) : 0
            var discount_price = this.discount_price ? parseFloat(this.discount_price) : 0
            var total_price = this.total_price ? parseFloat(this.total_price) : 0
            var price = 0

            if (this.price_satuan === 'meter') {
                price = total_price / (length * qty)
            } else if(this.price_satuan === 'unit') {
                price = total_price / qty
            } else{
                this.price = 0
            }

            if (event.isTrusted === true) {
                this.price = price
                this.sum_total_price = total_price - discount_price

                if (this.product === '') {
                    this.snackbar = {
                        color: "error",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Error",
                        text: 'Please Fill Product Code',
                        visible: true
                    };
                } else {
                    this.getPriceMinimum()
                }
            }

        },
        deleteItem(product, index){
            this.product_items.splice(index, 1);
        },
        clearItem(){
            this.stock = 'order'
            this.product = ''
            this.add_id = ''
            this.tor = ''
            this.sheet_genteng = ''
            this.acp_id = ''
            this.retail = 'non_retail'
            this.length = ''
            this.quantity = ''
            this.luas = ''
            this.weight = ''
            this.price_satuan = 'meter'
            this.item_kontrak = ''
            this.price = ''
            this.total_price = ''
            this.discount_price = ''
            this.sum_total_price = ''
            this.selisih_harga_memo = ''
            this.fee_luar = ''
            this.remark_item = ''
            this.packaging = 'no_packing'
            this.machine_type = ''
            this.group_id = ''
            this.grade = ''
            this.thick = ''
            this.width = ''
            this.color = ''
            this.brand = ''
        },
        async addProduct(){
            if (this.stock === '' || this.retail === '' || this.product === '' || this.length === '' || this.quantity === '' || this.price_satuan === '' || this.price === '' || this.total_price === '' || this.packaging == '') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Please fill your fileds and submit for save',
                    visible: true
                };
            } else {
                this.$store.dispatch('setOverlay', true)
                this.dialogItem = false

                var addProductItem = await this.checkPromoItem()
                
                var allowPricePerUnit = await this.allowPricePerUnit()
                var envVarPusat = await this.envVarPusat('APPLYCREDITLIMIT')
                var checkSatuan = await this.checkSatuan()
                var checkGentengRocky = await this.checkGentengRocky()
                var addIDAvailable = await this.addIDAvailable()
                var checkLembarBaut = await this.checkLembarBaut()
                var IfBarangJadiExist = await this.IfBarangJadiExist()
                var BjPassHpp = await this.BjPassHpp()
                var cekKontrak = await this.cekKontrak()

                if (addProductItem && allowPricePerUnit && envVarPusat && checkSatuan && checkGentengRocky && addIDAvailable && checkLembarBaut && IfBarangJadiExist && BjPassHpp) {
                    this.$store.dispatch('setOverlay', false)
                    this.addProductItems()
                } else{
                    this.$store.dispatch('setOverlay', false)
                }

                
                
            }
        },
        async envVarPusat(var_id){
            const respVar = await backendApi.fetchCore(`/api/get_env_var_conf?entity_id=KMB&appl_id=WEBKMB&var_id=${var_id}`, null, false, false, false)

            if (respVar.status === 200) {
                console.log(respVar.data);
                if (var_id === 'APPLYCREDITLIMIT') {
                    if (this.order_type === 'cust_order' || this.order_type === 'taking_contract' || this.order_type === 'special_order') {
                        if (respVar.data.data === 'Y' && this.customer.Real_City != 'GLOBAL' && this.pay_term != "CASH BEFORE DELIVERY") {
                            console.log(this.new_order, this.outstanding_order, this.outstanding_pre_order, parseFloat(this.new_order.replace(/,/g,"")), parseFloat(this.outstanding_order.replace(/,/g,"")), this.sum_total_price);
                            if (parseFloat(this.new_order.replace(/,/g,"")) +  parseFloat(this.outstanding_order.replace(/,/g,"")) + parseFloat(this.outstanding_pre_order.replace(/,/g,"")) + this.sum_total_price > this.credit_limit) {
                                this.snackbar = {
                                                color: "error",
                                                icon: "mdi-alert-circle",
                                                mode: "multi-line",
                                                position: "top",
                                                timeout: 7500,
                                                title: "Error",
                                                text: 'Piutang dan new order ' + this.customer.name + ' Melebihi Credit Limit',
                                                visible: true
                                            };
                                this.addProductItem = false
                            } else {
                                this.addProductItem = true
                            }                            
                        } else {
                            this.addProductItem = true
                        }
                    } else {
                        this.addProductItem = true
                    }
                } else {
                    this.addProductItem = true
                }                
            }
            else{
                this.addProductItem = true
            }
            return this.addProductItem
        },
        async checkPromoItem(){
            var reqBody = {
                'order_date': this.order_date,
                'prod_code': this.product ? this.product.barangjadiid : '',
                'sales': this.sales ? this.sales.SalesId : '',
            }

            const respData = await backendApi.fetchCore('/v2/master/kmb/check_promo_item', reqBody, false, false, false)

            if (respData.status === 200) {
                this.$store.dispatch('setOverlay', false)
                var mill_type = this.mill ? this.mill.mill_type : ''
                if (respData.data.data.ifProductInPromo === 'Y' && mill_type === 'M') {
                    var reqBodyPromoUsed = {
                        'promodate1': respData.data.data.promodate1.substr(0, 10),
                        'promodate2': respData.data.data.promodate2.substr(0, 10),
                        'prod_code': this.product ? this.product.barangjadiid : ''
                    }

                    const respDataPromoUsed = await backendApi.fetchCore('/v2/master/kmb/getpromoused', reqBodyPromoUsed, false, false, false)

                    if (respDataPromoUsed.status === 200) {
                        this.pakai = parseFloat(respDataPromoUsed.data.data.pakai) / 1000

                        const respGetBerat = await backendApi.fetchCore('/v2/master/kmb/getberat', reqBodyPromoUsed, false, false, false)

                        if (respGetBerat.status === 200) {
                            this.getberat = parseFloat(respGetBerat.data.data.wgt_unit) * parseFloat(respGetBerat.data.data.matrl_width) * parseFloat(this.length) * parseFloat(this.quantity) / 1000
                            console.log((parseFloat(respData.data.data.kuota_promo) - this.pakai - this.getberat) / 1000, respData.data.data.kuota_promo, this.pakai, this.getberat);
                            if ((parseFloat(respData.data.data.kuota_promo) - this.pakai - this.getberat) / 1000 <= 0) {
                                this.snackbar = {
                                    color: "error",
                                    icon: "mdi-alert-circle",
                                    mode: "multi-line",
                                    position: "top",
                                    timeout: 7500,
                                    title: "Error",
                                    text: 'Kuota Promo Tidak Mencukupi',
                                    visible: true
                                };
                                this.addProductItem = false
                                
                            } else {
                                this.addProductItem = true
                            }
                        } else {
                            this.addProductItem = true
                        }                            
                    } else {
                        this.addProductItem = true
                    }                    
                } else {
                    this.addProductItem = true
                }
            } else {
                this.addProductItem = true
            }

            return this.addProductItem
        },
        async allowPricePerUnit(){
            var reqBody = {
                'prod_code': this.product ? this.product.barangjadiid : ''
            }

            const respData = await backendApi.fetchCore('/v2/master/kmb/allowPricePerUnit', reqBody, false, false, false)

            if (respData.status === 200) {
                if (respData.data.data != null && this.price_satuan === 'unit') {
                    this.snackbar = {
                                    color: "error",
                                    icon: "mdi-alert-circle",
                                    mode: "multi-line",
                                    position: "top",
                                    timeout: 7500,
                                    title: "Error",
                                    text: 'Cek Price Mode. Hanya Baut, Insulation, Louvre, Ventilator, Pintu KM, dan UPVC yang diperbolehkan entry harga per unit',
                                    visible: true
                                };
                    this.addProductItem = false
                } else {
                this.addProductItem = true
                }
            }
            else{
                this.addProductItem = true
            }
            return this.addProductItem
        },
        async checkSatuan(){
            if (this.product.satuan != 'KG') {
                if (this.machine_type === 'TOK' || this.machine_type === 'TOR') {
                    if (this.tor === '') {
                        this.snackbar = {
                            color: "error",
                            icon: "mdi-alert-circle",
                            mode: "multi-line",
                            position: "top",
                            timeout: 7500,
                            title: "Error",
                            text: 'TOR ID Harap diisi',
                            visible: true
                        };
                        this.addProductItem = false

                    } else{
                        this.addProductItem = true
                    }
                } else if(this.machine_type === 'ACP'){
                    if (this.acp_id === '') {
                        this.snackbar = {
                            color: "error",
                            icon: "mdi-alert-circle",
                            mode: "multi-line",
                            position: "top",
                            timeout: 7500,
                            title: "Error",
                            text: 'ACP ID Harap diisi',
                            visible: true
                        };
                        this.addProductItem = false

                    } else{
                        this.addProductItem = true
                    }
                } else {
                    this.addProductItem = true
                }
            }
            else{
                this.addProductItem = true
            }
            return this.addProductItem
        },
        async checkGentengRocky(){

            const respData = await backendApi.fetchCore(`/v2/master/kmb/barang_jadi/getGentengRocky?prod_code=${this.product ? this.product.barangjadiid : ''}`, null, false, false, false)

            if (respData.status === 200) {

                if (respData.data.data && this.add_id === '') {
                    this.snackbar = {
                        color: "error",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Error",
                        text: 'ini genteng ROCKY, Tolong Add id diisi PSR',
                        visible: true
                    };
                    this.addProductItem = false
                } else {
                    this.addProductItem = true
                }
            }
            else{
                this.addProductItem = true
            }
            return this.addProductItem
        },
        async addIDAvailable(){
            const respData = await backendApi.fetchCore(`/v2/master/kmb/barang_jadi/addIDAvailable?mill=${this.mill ? this.mill.mill_id.trim() : ''}&mach_type=${this.machine_type ? this.machine_type : ''}&add_id=${this.add_id ? this.add_id : ''}`, null, false, false, false)

            if (respData.status === 200) {
                console.log(respData.data);
                if (respData.data.data === null && this.stock === 'order' && this.machine_type != 'COST') {
                    this.snackbar = {
                        color: "error",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Error",
                        text: 'Tolong cek lagi, di mill ' + this.mill.mill_name + ' Tidak ada mesin ' + this.machine_type + ' ' + this.add_id,
                        visible: true
                    };

                    this.addProductItem = false
                } else {
                    this.addProductItem = true
                }
            }
            else{
                this.addProductItem = true
            }
            return this.addProductItem
        },
        async checkLembarBaut(){
            if (((this.quantity % 100 < 0) || (this.quantity % 100 > 0)) && this.product.Jenis === 'BAUT') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Jumlah Baut Harus Kelipatan 100',
                    visible: true
                };

                this.addProductItem = false
            } else {
                this.addProductItem = true
            }

            return this.addProductItem

        },
        async IfBarangJadiExist(){
            const respData = await backendApi.fetchCore(`/v2/master/kmb/barang_jadi/IfBarangJadiExist?prod_code=${this.product ? this.product.barangjadiid : ''}`, null, false, false, false)

            if (respData.status === 200) {

                if (respData.data.data === null) {
                    this.snackbar = {
                        color: "error",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Error",
                        text: 'Tolong Check Kode Barang Jadi, Kode Salah ',
                        visible: true
                    };

                    this.addProductItem = false
                } else {
                    this.addProductItem = true
                }
            }
            else{
                this.addProductItem = true
            }
            return this.addProductItem
        },
        async BjPassHpp(){
            var hppJenis = await this.BjPassHppApi(this.product.Jenis)
            var hppMachine = await this.BjPassHppApi(this.machine_type)

            if (hppJenis.length > 0 || hppMachine.length > 0) {
                this.addProductItem = true
            } else {
                if (this.length === 0 || this.quantity === 0 || this.price === 0) {
                    this.snackbar = {
                        color: "error",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Error",
                        text: 'Tolong Isi Panjang, quantity, Harga',
                        visible: true
                    };

                    this.addProductItem = false
                } else {
                    this.addProductItem = true
                }
            }
            return this.addProductItem
        },
        async BjPassHppApi(str1){
            const respData = await backendApi.fetchCore(`/v2/master/kmb/appl_constan?key_id=NBPRICE&str1=${str1}`, null, false, false, false)

            if (respData.status === 200) {
                return respData.data
            }
        },
        async cekKontrak(){
            if ((this.order_type === 'taking_contract' && this.contract === '') || (this.order_type === 'contract_order' && this.contract === '')) {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Tolong Isi No Kontrak',
                    visible: true
                };

                this.addProductItem = false
            } else {
                this.addProductItem = true    
                // item_kontrak  

            }
            return this.addProductItem
        },
        addProductItems(){
            this.product_items.push(
                { 
                    stock: this.stock,
                    product: this.product,
                    item_num : this.product_items.length + 1,
                    prod_code: this.product ? this.product.barangjadiid : '',
                    descr: this.product ? this.product.namabarang : '',
                    add_id: this.add_id,
                    tor_id: this.tor,
                    sheet_genteng: this.sheet_genteng,
                    acp_id : this.acp_id,
                    retail: this.retail,
                    length: this.length,
                    qty: this.quantity,
                    luas: this.luas,
                    weight: this.weight,
                    price_satuan: this.price_satuan,
                    contract: this.contract,
                    item_kontrak: this.item_kontrak,
                    price: this.price,
                    total_price: this.total_price,
                    discount_price: this.discount_price,
                    sum_total_price: this.sum_total_price,
                    selisih_harga_memo: this.selisih_harga_memo,
                    fee_luar: this.fee_luar,
                    remark_item: this.remark_item,
                    packaging: this.packaging
                }
            );
            this.clearItem()
        },
        async submit(){
            this.$store.dispatch('setOverlay', true)

            if (this.office === '' || this.division === '' || this.sales === '' || this.customer === '' || this.id_card === '' || this.mill === '' || this.ship_term === '' || this.pay_term === '' || this.product_items.length === 0) {
                this.$store.dispatch('setOverlay', false)

                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Please fill your fileds and submit for save',
                    visible: true
                };
            } else {
                // var reqBody = {
                //     'cust_po_no': this.cust_po_no,
                //     'req_ship_date': this.ship_date,
                //     'CustomerId': this.customer ? this.customer.CustomerId : '',
                //     'Real_City': this.customer ? this.customer.Real_City : '',
                //     'cust_group': this.customer ? this.customer.cust_group : '',
                //     'kind_of_business': this.customer ? this.customer.kind_of_business : '',
                //     'bank_atas_nama3': this.customer ? this.customer.bank_atas_nama3 : '',
                //     'ownership': this.customer ? this.customer.ownership : '',
                // }

                // const respData = await backendApi.fetchCore('/v2/kmb/sales_order/store', reqBody, false, false, false)
                // console.log(respData);
                // if (respData.status === 200) {
                //     console.log(respData.data);
                // } else {
                //     this.snackbar = {
                //         color: "error",
                //         icon: "mdi-alert-circle",
                //         mode: "multi-line",
                //         position: "top",
                //         timeout: 7500,
                //         title: "Error",
                //         text: 'Internal Server Error' + respData,
                //         visible: true
                //     };
                // }
            }

        },
        async getPriceMinimum(){
            var reqBody = {
                'mill_id': this.mill ? this.mill.mill_id.trim() : '',
                'ship_term': this.ship_term,
                'prod_code': this.product ? this.product.barangjadiid : '',
                'add_id': this.add_id ? this.add_id : '',
                'division': this.division ? this.division : '',
                'sales_id': this.sales ? this.sales.SalesId : '',
                'price_mode': 'm',
                'length': this.length ? parseFloat(this.length) : 0,
                'discount': this.product ? parseFloat(this.product.diskon) : 0
            }

            console.log(reqBody);

            const respData = await backendApi.fetchCore('/v2/master/kmb/barang_jadi/getPrice', reqBody, false, false, false)

            if (respData.status === 200) {
                this.minimum_price = respData.data.data
                this.price_minimum = 'Please check fill your Price, Minimum price is ' + 'IDR.' + ' ' + this.$store.getters.convertToCurrencyUs(respData.data.data)

                if (parseFloat(this.price) < parseFloat(this.minimum_price)) {
                    this.alert_price_minimum = true
                } else {
                    this.alert_price_minimum = false
                }
            } else {
                this.alert_price_minimum = true
                this.price_minimum = respData.data.data
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Internal Server Error ' + respData.status + ' ' + respData.data.data,
                    visible: true
                };
            }
        }
    },
    watch: {
        searchCustomer (val) {
            val && val !== this.customer && this.getCustomer(val)
        },
    }
}
</script>
<style scoped>
.v-application .text-h6 {
    font-size: 0.9rem !important;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 0.0125em !important;
    font-family: "Roboto", sans-serif !important;
}
h6{
    font-size: 0.9rem !important;
}
.v-btn__content{
    font-size: 0.8rem !important;
}
.input{
    align-items: flex-start;
    display: flex;
    flex: 1 1 auto;
    font-size: 16px;
    letter-spacing: normal;
    max-width: 100%;
    text-align: left;
}


/* .stuck {
    position: fixed;
    max-height: 100%;
    overflow-y: scroll;
} */
</style>
    